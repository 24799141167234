import { Link as Anchor, Box, Divider, Flex, Text, Button } from "@chakra-ui/react";
import ProductImage from "@components/ProductList/ProductImage.component";
import QuantityButton from "@components/QuantityButton";
import getPriceFormat from "@hooks/getPriceFormat";
import NextLink from "next/link";

import { BasketLineModel } from "./BasketLine.model";

function BasketLine(props: BasketLineModel) {
  return (
    <Box >

      <Flex py={4} justifyContent="space-between">
        <Flex mt={2} mr={2}>
          <Box
            bg="brand.gray.300"
            minWidth={{ base: "140px", md: "200px" }}
            height={{ base: "140px", md: "200px" }}
          >
            <ProductImage
              image={{
                AlternativeText: props.Name,
                Title: props.Name,
                Url: props.FrontImageUrl,
              }}
              url={props.PageUrl}
              format="square"
            />
          </Box>
        </Flex>
        <Flex
          alignItems={{ base: "right", md: "center" }}
          flexGrow={2}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box my={2} pl={4} w={{ base: "100%", md: "45%" }}>
            <NextLink href={props.PageUrl || "#"} passHref>
              <Anchor
                fontSize="md"
                fontWeight={700}
                noOfLines={1}
                autoCapitalize="true"
              >
                {props.Name}
              </Anchor>
            </NextLink>
            <Text fontSize="xs" fontWeight={300} pb={4} minHeight="2.5rem">
              {props.Description}
            </Text>
            <Text fontSize="lg" fontWeight="semibold" pb={4}>
              {props.Quantity} x {getPriceFormat(props.Price, props.currency)}
            </Text>
            {props.IsSoldOut ?
              <Box>
                <Button
                  fontWeight={500}
                  pl={8}
                  pr={8}
                  h={8}
                  lineHeight={1.15}
                  variant="inverted"
                  isDisabled={props.IsSoldOut}
                >
                  {props.IsSoldOutText}
                </Button>
                <Text fontSize="sm" pt={2} color="red">
                  {props.RemoveSoldOutProductText}
                </Text>
              </Box>
              :
              <></>
            }

          </Box>
          <Flex
            alignItems="right"
            w={{ base: "100%", md: "55%" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Flex
              my={2}
              pl={4}
              flexGrow={2}
              justifyContent={{ base: "left", md: "space-between" }}
            >
              <QuantityButton
                productId={props.VariantId}
                removeText={props.removeText}
              />
            </Flex>
            <Box my={2} pl={4}>
              <Divider
                display={{ base: "block", md: "none" }}
                my={4}
                maxWidth="140px"
              />
              <Text
                textAlign={{ base: "left", md: "right" }}
                fontSize="lg"
                fontWeight="semibold"
                pt={2}
              >
                {getPriceFormat(props.TotalPrice, props.currency)}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>

  );
}

export default BasketLine;
