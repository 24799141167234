import { Box, Button, Flex } from "@chakra-ui/react";
import { FormField } from "@components/FormField.component";
import { useAppData } from "@hooks/useAppData";
import useBasket from "@hooks/useBasket";
import { Form, Formik } from "formik";
import { useTranslations } from "next-intl";
import { useState } from "react";

export const AddVoucher = () => {
  const [showVouncherControl, setShowVouncherControl] = useState(false);
  const basketDict = useTranslations("BasketPage");
  const dict = useTranslations("BasketPage");
  const { upsertVoucher, loading } = useBasket();
  const initialValues = {
    VoucherCode: "",
  };

  const [{ basket }] = useAppData();
  const VoucherCode = basket?.OrderDraftVoucher?.VoucherCode;

  const name = "VoucherCode";
  return (
    <Box float="left" width="100%">
      {VoucherCode ? (
        <>
          {dict("TextVoucherPrefix")} {VoucherCode} (
          <Button
            id="removeVoucher"
            onClick={async () =>
              await upsertVoucher({
                VoucherCode: "",
              })
            }
            variant="link"
          >
            {dict("ButtonTextRemoveVoucher")}
          </Button>
          )
        </>
      ) : (
        <Button
          id="showMoreButton"
          onClick={() => setShowVouncherControl(!showVouncherControl)}
          variant="link"
        >
          {dict("ButtonTextApplyVoucher")}
        </Button>
      )}
      {showVouncherControl ? (
        <Formik
          initialValues={initialValues}
          validateOnChange
          onSubmit={(values: { VoucherCode: string }, actions) => {
            setTimeout(async () => {
              actions.setSubmitting(true);

              await upsertVoucher({
                VoucherCode: values.VoucherCode,
              });
              setShowVouncherControl(!showVouncherControl);
              actions.setSubmitting(false);
              actions.resetForm({
                values: initialValues,
              });
            }, 1000);
          }}
        >
          {({ isValid, dirty, isSubmitting, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Flex gap={4}>
                  <FormField
                    name={name}
                    placeholder={basketDict("InputPlaceholderVoucher")}
                  />
                  <Button
                    disabled={!isValid || !dirty}
                    isLoading={isSubmitting}
                    type="submit"
                    fontSize="14px"
                    fontWeight={500}
                    width="50%"
                    my={2}
                  >
                    {dict("ButtonTextApplyVoucher")}
                  </Button>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <></>
      )}
    </Box>
  );
};
