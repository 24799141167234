import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Link as Anchor,
  Box,
  Button,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ProductListBlockType } from "@components/Blocks/ProductListBlock/ProductListBlock.model";
import Image from "@components/Image";
import { ImageCarousel } from "@components/ImageCarousel";
import type {
  ProductDisplayType,
  ProductPageDesignerType,
  ProductPageSpecsType,
  ProductType,
} from "@components/Pages/ProductDisplay.model";
import ProductListComponent from "@components/ProductList";
import { ProductTeaserType } from "@components/ProductList/ProductList.model";
import {
  ChsAccordion,
  ChsAccordionItem,
} from "@modules/ChsAccordion/ChsAccordion";
import { useTranslations } from "next-intl";
import NextLink from "next/link";

interface ProductDetails {
  ShortDescription: ProductDisplayType["ShortDescription"];
  Designer: ProductPageDesignerType;
  Specs: ProductPageSpecsType;
  LongDescriptionExists?: boolean;
  RelatedProducts?: ProductListBlockType;
  RelatedProductsFinal: ProductTeaserType[] | ProductType[];
}

export const ProductDetails = ({
  Designer,
  Specs,
  ShortDescription,
  LongDescriptionExists = false,
  RelatedProducts,
  RelatedProductsFinal,
}: ProductDetails) => {
  const dict = useTranslations("ProductPage");
  const relatedProductsColumns = useBreakpointValue({ base: 12, md: 8 });

  return (
    <ChsAccordion pb={4}>
      <ChsAccordionItem title={dict("AnchorLinks.AnchorDescriptionLabel")}>
        <Text whiteSpace="pre-wrap">{ShortDescription}</Text>
        {LongDescriptionExists ? (
          <NextLink href="#description" passHref>
            <Button variant="link" m={4} float="right">
              {dict("Other.ReadMore")}
            </Button>
          </NextLink>
        ) : null}
      </ChsAccordionItem>
      {Designer?.Body ? (
        <ChsAccordionItem title={dict("PimDesignerBlock.TextSubheading")}>
          <Image
            src={Designer.Image?.Url}
            alt={Designer.Image?.AlternativeText || ""}
            objectFit="cover"
            sizes="(max-width: 1280px) 33vw,
                   450px"
            aspectRatio="16:9"
          />

          <Text pt={2}>{Designer.Body}</Text>
          <NextLink href={Designer.Link?.Url} passHref>
            <Anchor fontSize="sm" target={Designer.Link?.Target} float="right">
              {Designer.Link?.Title} <ArrowForwardIcon />
            </Anchor>
          </NextLink>
        </ChsAccordionItem>
      ) : (
        <></>
      )}

      {Specs?.Images.length || Specs?.OtherAssets.length ? (
        <ChsAccordionItem
          title={`${dict("Specs.HeadingSpecsCarousel")} & ${dict(
            "Specs.HeadingSpecsSecondColumn"
          )}`}
        >
          {Specs.Images && (
            <ImageCarousel
              images={Specs.Images}
              aspectRatio="4:3"
              objectFit="contain"
              sizes="(max-width: 768px) 100vw, 400px"
              controls
            />
          )}
          {dict("Specs.HeadingSpecsSecondColumn")}
          {Specs?.OtherAssets.map((file, i) => (
            <Box key={`${i} - ${file.Description}`}>
              <Text as="span" fontWeight="bold">
                {file.Description}
              </Text>
              {" | "}
              {file.Link && (
                <NextLink href={file.Link?.Url} passHref>
                  <Anchor textDecoration="underline">{file.Link?.Title}</Anchor>
                </NextLink>
              )}
            </Box>
          ))}
        </ChsAccordionItem>
      ) : (
        <></>
      )}
      {RelatedProductsFinal && RelatedProductsFinal.length ? (
        <ChsAccordionItem title={RelatedProducts.Heading}>
          <ProductListComponent
            products={RelatedProductsFinal}
            columns={relatedProductsColumns}
          />
        </ChsAccordionItem>
      ) : (
        <></>
      )}
    </ChsAccordion>
  );
};
