import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { CheckoutReceiptOrderLineViewModel } from "@components/Pages/CheckoutReceiptPage.model";
import getPriceFormat from "@hooks/getPriceFormat";

function ReceiptBasketLine(props: CheckoutReceiptOrderLineViewModel) {
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Text
            fontSize="sm"
            fontWeight={700}
            noOfLines={1}
            autoCapitalize="true"
          >
            {props.Name} | {props.DesignerName}
          </Text>
          <Text fontSize="xs" fontWeight={300} pb={4} height="2.5rem">
            {props.Configuration}
          </Text>
        </Box>
        <Text fontSize="sm" fontWeight="semibold" pb={4} color="brand.gray.500">
          {props.Quantity}
        </Text>
        <Text fontSize="sm" fontWeight="semibold" pb={4}>
          {getPriceFormat(props.TotalPrice, props.CurrencySymbol)}
        </Text>
      </Flex>
      <Divider />
    </>
  );
}

export default ReceiptBasketLine;
