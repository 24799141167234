import { Box, Input, List, ListItem, Text } from "@chakra-ui/react";
import StoreLocatorGeocoderServce from "@modules/StoreLocator/StoreLocatorGeocoder.servce";
import { useTranslations } from "next-intl";
import { useCallback, useRef, useState } from "react";
import { useKey, useThrottle } from "rooks";

interface SelectionType {
  name: string;
  coordinates: [number, number];
}

function StoreLocatorSearchComponent({
  callback,
}: {
  callback: (val: SelectionType) => void;
}) {
  const [autcompleteItems, setAutocompleteItems] = useState<SelectionType[]>(
    []
  );

  const inputRef = useRef<HTMLInputElement>();
  const autocompleteRef = useRef<HTMLOListElement>();
  const [value, setValue] = useState<string>("");

  const [handleQuery] = useThrottle(async (query: string): Promise<void> => {
    try {
      const geocoder = new StoreLocatorGeocoderServce();
      const data = await geocoder.query(query, "mapbox.places", true);
      if (typeof data !== "undefined") {
        const result = data.features.map((feature) => {
          return {
            coordinates: feature.geometry.coordinates,
            name: feature.text,
          };
        });
        setAutocompleteItems(result);
      }
    } catch (error) {
      console.log(error.message); // eslint-disable-line no-console
    }
  }, 1000);

  const handleAutoComplete = useCallback(
    async (event) => {
      const query = event.target.value;
      setValue(query);
      if (query.length < 3) return;
      handleQuery(query);
    },
    [handleQuery]
  );

  const handleAutocompleteSelect = useCallback(
    (item: SelectionType) => {
      setValue(item.name);
      callback(item);
      setAutocompleteItems([]);
    },
    [callback]
  );

  // Autocomplete keyboard navigation.
  const acIndex = useRef(-1);
  const navigateAutocomplete = useCallback(
    (action: "up" | "down" | "go" | "exit") => {
      if (autocompleteRef.current) {
        const items =
          autocompleteRef.current.querySelectorAll(".autocomplete-item") || [];

        if (items.length > 0) {
          switch (action) {
            case "up":
              acIndex.current =
                acIndex.current > 0 ? acIndex.current - 1 : items.length - 1;
              break;
            case "down":
              acIndex.current =
                acIndex.current < items.length - 1 ? acIndex.current + 1 : 0;
              break;
            case "go":
              if (typeof items[acIndex.current] !== "undefined") {
                items[acIndex.current].click();
                acIndex.current = -1;
              }
              break;
            case "exit":
              setValue("");
              setAutocompleteItems([]);
          }

          if (typeof items[acIndex.current] !== "undefined") {
            items[acIndex.current].focus();
          }
        }
      }
    },
    []
  );

  useKey("ArrowUp", (e) => {
    e.preventDefault();
    navigateAutocomplete("up");
  });

  useKey("ArrowDown", (e) => {
    e.preventDefault();
    navigateAutocomplete("down");
  });

  useKey(["Enter", "Return"], (e) => {
    e.preventDefault();
    navigateAutocomplete("go");
  });

  useKey("Escape", (e) => {
    e.preventDefault();
    navigateAutocomplete("exit");
  });

  const translations = useTranslations("StoreLocator");

  return (
    <Box color="black" position="relative" zIndex={9} textAlign="left">
      <Input
        ref={inputRef}
        value={value}
        onChange={handleAutoComplete}
        size="sm"
        placeholder={translations("ButtonText")}
        id="query"
        variant="default"
        tabIndex={0}
        autoFocus={true}
      />
      <List
        fontSize="xs"
        position="absolute"
        backgroundColor="white"
        width="100%"
        ref={autocompleteRef}
      >
        {autcompleteItems.map((item) => {
          return (
            <ListItem
              key={item.coordinates.join("")}
              _hover={{ backgroundColor: "gray.50" }}
            >
              <Text
                paddingX={3}
                paddingY={1}
                cursor="pointer"
                onClick={() => {
                  handleAutocompleteSelect(item);
                }}
                tabIndex={0}
                className="autocomplete-item"
              >
                {item.name}
              </Text>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

export default StoreLocatorSearchComponent;
