import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  VStack,
} from "@chakra-ui/react";
import getPriceFormat from "@hooks/getPriceFormat";
import { useAppData } from "@hooks/useAppData";
import useBasket from "@hooks/useBasket";
import useTracking from "@hooks/useTracking";
import { AddVoucher } from "@modules/Basket/AddVoucher";
import MiniBasketLine from "@modules/Basket/MiniBasketLine/MiniBasketLine.component";
import { useTranslations } from "next-intl";
import NextLink from "next/link";
import { useEffect, useState } from "react";

import { PaymentMethods } from "./PaymentMethods.component";

interface CheckoutSummaryModel {
  basket?: boolean;
}
export const CheckoutSummary = ({
  basket: basketPage = false,
}: CheckoutSummaryModel) => {
  const dict = useTranslations("BasketPage");
  const summaryDict = useTranslations("CheckoutShared.OrderSummary");
  const { getBasket, checkSoldOutItems, isInvalidBasket } = useBasket();
  const { trackBeginCheckout } = useTracking();
  const [
    {
      basket,
      marketData: { Currency, Urls },
    },
  ] = useAppData();

  const VoucherName =
    basket?.OrderDraftVoucher && `(${basket?.OrderDraftVoucher?.Name})`;

  useEffect(() => {
    checkSoldOutItems(basket);
  }, [basket]);

  useEffect(() => {
    getBasket();
  }, []);

  const noItems = basket === null || !basket?.Lines;
  const emptyBasket = !(basket?.Lines?.length > 0);

  return (
    <>
      <Box py={8} px={8} bg="blackAlpha.200">
        <Heading as="h3" pb={4} size="md">
          {dict("HeadingSummary")}
        </Heading>
        <VStack align="left">
          <Stat pt={4}>
            <Flex justifyContent="space-between" padding={1}>
              <StatLabel>{dict("TextSummaryShipping")}</StatLabel>
              <StatNumber fontSize="md">
                {getPriceFormat(basket?.TotalShipmentPrice || "-", Currency)}
              </StatNumber>
            </Flex>
          </Stat>
          <Divider borderColor="brand.gray.700" />
          {basket?.DiscountValue > 0 && (
            <>
              <Stat pt={4} color="brand.red">
                <Flex justifyContent="space-between" padding={1}>
                  <StatLabel>
                    {dict("TextSummaryDiscount")} {VoucherName}
                  </StatLabel>
                  <StatNumber fontSize="md">
                    {getPriceFormat(basket?.Discount || "-", Currency)}
                  </StatNumber>
                </Flex>
              </Stat>
              <Divider borderColor="brand.gray.700" />
            </>
          )}
          <Stat pt={8}>
            <Flex justifyContent="space-between" padding={1}>
              <StatLabel fontWeight="bold">
                {dict("TextSummaryTotal")} ({dict("TextIncludingVat")})
              </StatLabel>
              <StatNumber fontSize="lg">
                {getPriceFormat(basket?.TotalPrice || "-", Currency)}
              </StatNumber>
            </Flex>
          </Stat>
          <Divider borderColor="brand.gray.700" />
          <AddVoucher />
          {basketPage ? (
            <VStack py="4" display="inline-flex">
              <NextLink passHref href={Urls.CheckoutAddress || "/address"}>
                <Button
                  variant="inverted"
                  width={{ base: "100%" }}
                  size="lg"
                  onClick={() => trackBeginCheckout()}
                  isDisabled={emptyBasket || isInvalidBasket}
                >
                  {dict("ButtonTextToCheckout")}
                </Button>
              </NextLink>
              <PaymentMethods />
            </VStack>
          ) : (
            <Box>
              {!noItems && basket.Lines.length > 0 && (
                <>
                  <Heading as="h3" mt={14} mb={0} size="md">
                    {summaryDict("HeadingSummary")}
                  </Heading>
                  <VStack align="left">
                    {basket.Lines.map((line) => (
                      <MiniBasketLine
                        {...line}
                        key={line.VariantId}
                        currency={Currency}
                      />
                    ))}
                  </VStack>
                </>
              )}
            </Box>
          )}
        </VStack>
      </Box>
    </>
  );
};
