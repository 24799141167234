import getConfig from "@hooks/getConfig";
import { useState } from "react";

function useCases(locale: string) {
  const { edgeEndpoint } = getConfig();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [data, setData] = useState<any>(null);

  const fetchCases = async (caseIds: string[]) => {
    try {
      const res = await fetch(
        [edgeEndpoint, "api", "favorites", "fetchCases"].join("/"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ locale, caseIds }),
        }
      );

      const json = await res.json();
      setData(json);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return { fetchCases, data, error, loading };
}

export default useCases;
