import { Container, Heading } from "@chakra-ui/react";
import { GenericCarousel } from "@components/GenericCarousel";

import { ProductGalleryBlockType } from "./ProductGalleryBlock.model";
import { ProductGalleryItem } from "./ProductGalleryItem";

function ProductGalleryBlockComponent({
  Heading: heading,
  Products,
  Kind,
}: ProductGalleryBlockType) {
  return (
    <Container className={Kind}>
      <Heading marginBottom={12}>{heading}</Heading>
      <GenericCarousel
        perPage={4}
        controls
        ariaLabel="Gallery of Product Images"
      >
        {Products.map((product, i) => (
          <ProductGalleryItem {...product} key={i} />
        ))}
      </GenericCarousel>
    </Container>
  );
}
export default ProductGalleryBlockComponent;
