import { BoxProps, GridItem } from "@chakra-ui/react";
import Image from "@components/Image";
import { getWide } from "@hooks/getImageInfo";

interface PdpImageModel extends BoxProps {
  img: CMSImageType;
  priority?: boolean;
  wide?: boolean;
  productImage?: boolean;
  children?: React.ReactNode;
  sizes?: string;
}
export const PdpImage = ({
  img,
  priority = false,
  productImage = false,
  wide = getWide(img) || false,
  children,
  sizes,
  ...rest
}: PdpImageModel) => {
  const defaultSizes = wide
    ? `(max-width: 768px) 100vw,
       (max-width: 992px) 50vw,
       900px`
    : `(max-width: 768px) 100vw,
       (max-width: 992px) 33vw,
       500px`;
  return (
    <GridItem
      bgColor="brand.gray.300"
      colSpan={wide ? 2 : 1}
      position={"relative"}
      {...rest}
      _hover={
        rest.onClick && {
          cursor: "pointer",
        }
      }
    >
      <Image
        src={img?.Url}
        alt={img?.AlternativeText || ""}
        aspectRatio={wide ? "16:9" : "5:6"}
        objectFit={productImage ? "contain" : "cover"}
        sizes={sizes || defaultSizes}
        priority={priority}
        focalPoint={img?.FocalPoint}
      />
      {children}
    </GridItem>
  );
};
