import { Container, Heading } from "@chakra-ui/react";
import ProductList from "@components/ProductList";
import { getCookie } from "@hooks/cookies";

import type { ProductListBlockType } from "./ProductListBlock.model";

function ProductListBlockComponent(data: ProductListBlockType) {
  if (
    !data.Products &&
    (!data.HelloRetailStrategy || !getCookie("hello_retail_id"))
  )
    return;
  return (
    <Container className={data.Kind} paddingY={10}>
      <Heading variant="heading2" as="h2" marginBottom={5}>
        {data.Heading}
      </Heading>
      <ProductList
        products={data.Products}
        helloRetailStrategy={data.HelloRetailStrategy}
        helloRetailSplashFilter={data.HelloRetailSplashFilter}
        isCarousel={data.IsCarousel}
      />
    </Container>
  );
}

export default ProductListBlockComponent;
