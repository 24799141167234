import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import { ReactElement } from "react";

import type { FlagshipStoresBlockType } from "./FlagshipStoresBlock.model";
import FlagshipStoreCard from "./FlagshipStoresCard.component";

export function FlagshipStoresBlock(
  data: FlagshipStoresBlockType
): ReactElement {
  return (
    <Box>
      <Container position="relative" py={0}>
        <SimpleGrid columns={{ base: 1, xl: 2 }} gap={2}>
          {data.stores?.map((store, key) => (
            <FlagshipStoreCard
              {...store}
              key={store.StoreName}
              priority={key < 4}
            />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}

export default FlagshipStoresBlock;
