import { PriceListEntryType } from "@context/AppContext/App.model";
import { useAppData } from "@hooks/useAppData";
import { FilterResultsModel, SearchVariantModel } from "@modules/Search/Search.model";
import { useState } from "react";

import { FacetedFilter } from "@services/helloRetail.service";
import { useClientSideState } from "@zustand/clientSideState";

interface SearchParamsModel {
  Culture: string;
  MarketId: string;
  Category: string;
  Designer?: string;
  Filters?: string[];
  Facets: string[];
  HelloRetailPagesApikey: string
}

function useFilter(initialParams: SearchParamsModel) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [data, setData] = useState<FilterResultsModel>(null);
  const [{ marketData: { MarketId, HelloRetailBaseUrl } }] = useAppData();
  const { priceListData } = useClientSideState();

  const search = async (params = {}): Promise<void> => {
    const newParams = { ...initialParams, ...params };
    try {
      setLoading(true);

      const resultData = await FacetedFilter(newParams.Category, newParams.Designer, newParams.Filters, newParams.HelloRetailPagesApikey, HelloRetailBaseUrl)

      if (resultData?.variants && priceListData) {
        const priceReplacedVariants = []
        // substitute marketId and remove absolute part of URL from search result
        resultData.variants.forEach((res: SearchVariantModel) => {
          if (res.variantPageUrl) {
            const urlParts = res.variantPageUrl.split("/")
            if (urlParts.length > 4) {
              const newUrl = ["", urlParts[3], MarketId.toLowerCase(), ...urlParts.slice(5)]
              res.variantPageUrl = newUrl.join("/")
            }
          }
          // substitute prices
          if (res.sku && priceListData.prices && Object.keys(priceListData.prices).length > 0) {
            const priceRecord: PriceListEntryType = priceListData.prices[res.sku]
            if (priceRecord) {
              res.lowestVariantPrice = priceRecord.price
              res.formattedLowestPrice = priceRecord.formattedPrice
              res.price = priceRecord.listedPrice
              res.formattedPrice = priceRecord.formattedListedPrice
              res.currency = priceRecord.currency
              priceReplacedVariants.push(res) // only take variants that has prices
            }

          } else {
            // No prices exist for market
            res.lowestVariantPrice = null
            res.formattedLowestPrice = ""
            res.price = null
            res.formattedPrice = null
            res.currency = null
            priceReplacedVariants.push(res)
          }
        })
        resultData.variants = priceReplacedVariants;
      }

      setData(resultData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  return { search, data, error, loading };
}

export default useFilter;
