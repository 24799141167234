import { Box, Container, Text, useBreakpointValue } from "@chakra-ui/react";
import { GenericCarousel } from "@components/GenericCarousel";
import Image from "@components/Image";

import { BodyGalleryBlockType } from "./BodyGalleryBlock.model";

function BodyGalleryBlockComponent({
  MediaList,
  Ratio,
  Kind,
}: BodyGalleryBlockType) {
  if (!MediaList || MediaList[0] === null) return <></>;
  const perPage = useBreakpointValue({ base: 2, sm: 3 });
  return (
    <Container paddingBottom={10} className={Kind}>
      <GenericCarousel
        perPage={perPage}
        perMove={1}
        controls
        ariaLabel="Gallery"
      >
        {MediaList.map((image, i) => (
          <Box key={i}>
            <Image
              alt={image?.AlternativeText}
              src={image?.Url}
              sizes="(max-width: 768px) 50vw,
                     (max-width: 1280px) 33vw,
                     450px"
              aspectRatio={Ratio || "1:1"}
              focalPoint={image.FocalPoint}
              objectFit="cover"
            />
            <Text>{image?.Title}</Text>
          </Box>
        ))}
      </GenericCarousel>
    </Container>
  );
}
export default BodyGalleryBlockComponent;
