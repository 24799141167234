import {
  Link as Anchor,
  Box,
  Button,
  Container,
  Divider,
  GridItem,
  Heading,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import Image from "@components/Image";
import RichTextComponent from "@components/RichText.component";
import NextLink from "next/link";
import { ReactElement } from "react";

import type { BodyCopyOneBlockType } from "./BodyCopyOneBlock.model";

export function BodyCopyOneBlock(data: BodyCopyOneBlockType): ReactElement {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Container className={data.Kind}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={4}>
        <GridItem
          order={isMobile ? 1 : data.IsTextRightAligned ? 1 : "unset"}
          padding={4}
        >
          <Box
            display="flex"
            height="100%"
            justifyContent={data.IsTextRightAligned ? "flex-start" : "flex-end"}
          >
            <Box
              alignSelf="center"
              width={{ base: "100%", md: "80%", lg: "60%" }}
            >
              {data.Heading && (
                <Heading as="h3" size="sm" fontWeight="500">
                  {data.Heading}
                  <Divider width={6} borderColor="black" marginTop={8} />
                </Heading>
              )}
              <Box marginY={8}>
                <RichTextComponent text={data.Body} />
              </Box>
              {data.Link && (
                <NextLink href={data.Link.Url} passHref>
                  <Anchor
                    display="flex"
                    alignItems="center"
                    sx={{ textDecoration: "none !important" }}
                  >
                    <Button variant="heroDark" mt={4} mb={10} mr={2}>
                      {data.Link.Title || data.Link.Url}
                    </Button>
                    {/* <ArrowForwardIcon /> */}
                  </Anchor>
                </NextLink>
              )}
            </Box>
          </Box>
        </GridItem>
        <GridItem>
          <Box
            display="flex"
            height="100%"
            justifyContent={data.IsTextRightAligned ? "flex-end" : "flex-start"}
          >
            {data.Image && (
              <Box width={{ base: "100%", md: "80%", lg: "60%" }}>
                <Image
                  src={data.Image.Url}
                  alt={data.Image.AlternativeText}
                  aspectRatio="3:4"
                  crop
                  sizes={`(max-width: 768px) 100vw,
                          (max-width: 992px) 33vw,
                          600px`}
                  {...data.Image}
                />
              </Box>
            )}
          </Box>
        </GridItem>
      </SimpleGrid>
    </Container>
  );
}

export default BodyCopyOneBlock;
