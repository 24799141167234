import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Link as Anchor, Flex, Icon, Text } from "@chakra-ui/react";
import { useAppData } from "@hooks/useAppData";
import { useTranslations } from "next-intl";
import NextLink from "next/link";
import { useRouter } from "next/router";

export const CheckoutNav = () => {
  const dictBasket = useTranslations("BasketPage");
  const dictAddress = useTranslations("CheckoutAddressPage.AddressForm");
  const dictDelivery = useTranslations("CheckoutDeliveryPage");
  const dictPayment = useTranslations("CheckoutPaymentPage");
  const dictMiniBasket = useTranslations("MiniBasket");
  const { asPath: currentPath } = useRouter();

  let heading: string;
  let step: number;

  const [
    {
      marketData: { Urls },
    },
  ] = useAppData();

  const localizedBasketName = Urls.CheckoutBasket.split("/")[2] || "basket";
  const localizedAddressName = Urls.CheckoutAddress.split("/")[2] || "/address";
  const localizedDeliveryName =
    Urls.CheckoutDelivery.split("/")[2] || "/delivery";
  const localizedPaymentName = Urls.CheckoutPayment.split("/")[2] || "/payment";

  if (currentPath.includes(localizedBasketName)) {
    heading = dictBasket("HeadingBasket");
  } else if (currentPath.includes(localizedAddressName)) {
    heading = dictAddress("HeadingAddress");
    step = 1;
  } else if (currentPath.includes(localizedDeliveryName)) {
    heading = dictDelivery("HeadingSelectDeliveryMethod");
    step = 2;
  } else if (currentPath.includes(localizedPaymentName)) {
    heading = dictPayment("HeadingSelectPaymentMethod");
    step = 3;
  }

  return (
    <Flex
      alignItems={{ base: "flex-start", md: "center" }}
      flexDirection={{ base: "column", md: "row" }}
      p={6}
    >
      <NextLink href={Urls.CheckoutBasket || "/basket"} passHref>
        <Anchor>
          <Flex alignItems="center">
            <Icon as={ChevronLeftIcon} />
            <Text>{dictMiniBasket("ButtonShoppingCart")}</Text>
          </Flex>
        </Anchor>
      </NextLink>
    </Flex>
  );
};
