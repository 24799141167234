import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Link as Anchor,
  Box,
  GridItem,
  Heading,
  ResponsiveObject,
  Text,
} from "@chakra-ui/react";
import NextLink from "next/link";
import React from "react";

import Image from "../../Image";
import { ImageComponentType } from "../../Image/Image.model";
import type { CaseListItemType } from "./CaseListBlock.model";
import { GetInfo } from "./CaseListItem";

interface CaseListProps {
  data: CaseListItemType;
  landscape: boolean;
  aspectRatioOverride?: ImageComponentType["aspectRatio"];
  colSpanOverride?: ResponsiveObject<number>;
}
export function StudioCaseListItem({
  data,
  landscape,
  aspectRatioOverride,
  colSpanOverride,
}: CaseListProps): React.ReactElement {
  const { imageOrientation, colSpan, size, aspectRatio } = GetInfo(landscape);
  const { CategoryLink, PageLink, TeaserText } = data;

  const sizes = `(max-width: 768px) 100vw,
                 (max-width: 992px) 50vw,
                 ${landscape ? "660px" : "330px"}`;

  return (
    <GridItem
      paddingY={2}
      colSpan={colSpanOverride || colSpan}
      w="100%"
      h="100%"
    >
      <Box overflow={"hidden"} mb={2}>
        <Box
          sx={{
            transitionProperty: "transform",
            transitionDuration: "imageHover",
            transitionTimingFunction: "imageHover",
          }}
          _hover={{
            transform: "scale(1.1)",
            cursor: "pointer",
          }}
        >
          {data.PageLink && (
            <NextLink href={data.PageLink?.Url} passHref>
              <Anchor>
                <Image
                  src={data[imageOrientation]?.Url}
                  alt={data[imageOrientation]?.AlternativeText}
                  aspectRatio={aspectRatioOverride || aspectRatio}
                  sizes={sizes}
                  size={size}
                  objectFit="cover" // @todo: API needs to provide correct aspect ratio crops.
                />
              </Anchor>
            </NextLink>
          )}
        </Box>
      </Box>
      <NextLink href={PageLink.Url} passHref>
        <Anchor>
          <Heading as="h4" variant="heading4">
            {PageLink.Title}
          </Heading>
        </Anchor>
      </NextLink>
      <Text paddingTop={2} opacity={0.8} color="studio.300">
        {TeaserText}
      </Text>

      <NextLink href={PageLink.Url || ""} passHref>
        <Anchor display="flex" alignItems="center" my={6}>
          <Text variant="cta" mr={2}>
            {CategoryLink.Title}
          </Text>
          <ArrowForwardIcon />
        </Anchor>
      </NextLink>
    </GridItem>
  );
}
