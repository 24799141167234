import { colors } from "@theme/default.theme";

// TODO set return type to chakra theme studio colors
function getColor(kind: string, slug?: string): string {
  switch (kind) {
    case "ProductPageModel":
      return "studio.gray.300";
    case "StudioStartPageModel":
      return "studio.white";
    case "StudioContactBlockModel":
      return "studio.teal";
    case "StudioStandardPageModel":
      if (slug && slug.includes("contact")) return "studio.teal";
      return "studio.gray.300";
    case "ExploreBlockModel":
      if (slug && slug.includes("furniture")) return "studio.tealTint";
      else if (slug && slug.includes("cases")) return "studio.nightTint";
      else if (slug && slug.includes("about")) return "studio.teal";
      return "studio.white";
    default:
      return "studio.gray.300";
  }
}

export function getFGColor(bgColor: string): string {
  switch (bgColor) {
    case "studio.teal":
      return "studio.white";
    case "studio.night":
      return "studio.white";
    default:
      return "studio.black";
  }
}

export function getColorName(inputColor: string): string {
  if (!inputColor) return;
  return Object.keys(colors.studio).find(
    (key) => colors.studio[key] === inputColor
  );
}

export default getColor;
