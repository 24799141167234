import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { ChsAlert } from "@components/ChsAlert/ChsAlert.component";
import { CloudinaryNextImage } from "@components/Image";
import RichTextComponent from "@components/RichText.component";
import { useAppData } from "@hooks/useAppData";
import useBasket from "@hooks/useBasket";
import type { PaymentOption } from "@modules/Basket/Basket.model";
import { Field, FieldProps } from "formik";
import { useTranslations } from "next-intl";
import React, { useEffect, useState } from "react";

import { CheckoutStep } from "../CheckoutStep.component";

interface CheckoutPaymentFormProps {
  Link?: CMSLinkType;
  CookiesLink?: CMSLinkType;
  isValid: boolean;
  dirty: boolean;
  isSubmitting: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  paymentOptions: PaymentOption[];
}

function CheckoutPaymentForm({
  Link: termsLink,
  CookiesLink: cookiesLink,
  isValid,
  dirty,
  isSubmitting,
  setFieldValue,
  paymentOptions,
}: CheckoutPaymentFormProps) {
  const { error, setError } = useBasket();
  const dict = useTranslations("CheckoutPaymentPage");
  const dictAddressForm = useTranslations("CheckoutAddressPage.AddressForm");
  const validationDict = useTranslations("Shared.Validation");
  const [
    {
      marketData: { Urls },
    },
  ] = useAppData();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  function validateTerms(value) {
    let error;
    if (!value) {
      error = validationDict("ErrorTerms");
    }
    return error;
  }

  const handlePaymentMethodModalClick = (
    ReadMoreModalTitle: string,
    ReadMoreModalText: string
  ) => {
    setModalTitle(ReadMoreModalTitle);
    setModalText(ReadMoreModalText);
    onOpen();
  };

  function replaceAll(string: string, replacements: string | any[]) {
    if (replacements.length === 0) {
      return string;
    }

    const { replaceThis, replacement } = replacements[0];
    const parts = string.split(replaceThis);

    if (parts.length === 1) {
      return parts[0];
    }

    const replacedText = parts
      .map((part, index) => (
        <React.Fragment key={index}>
          {replaceAll(part, replacements.slice(1))}
          {index !== parts.length - 1 && replacement}
        </React.Fragment>
      ))
      .filter(Boolean);

    return <>{replacedText}</>;
  }

  const PaymentOption = ({
    Name,
    ImageUrl,
    Description,
    ReadMoreModalTitle,
    ReadMoreModalText,
  }: PaymentOption) => (
    <HStack spacing="24px">
      <Box
        minWidth="100px"
        minHeight="100px"
        position="relative"
        objectFit="contain"
      >
        <CloudinaryNextImage
          src={ImageUrl}
          alt={`Payment provider ${Name}`}
          layout="fill"
          objectFit={"contain"}
          sizes="250px"
          priority
        />
      </Box>
      <Box position="relative">
        <Text fontSize="lg" fontWeight="bold">
          {Name}
        </Text>
        <Text fontSize="sm">
          {!error && Description
            ? replaceAll(Description, [
                {
                  replaceThis: "*readMoreLink*",
                  replacement: (
                    <Link
                      onClick={() =>
                        handlePaymentMethodModalClick(
                          ReadMoreModalTitle,
                          ReadMoreModalText
                        )
                      }
                    >
                      <Button
                        variant="link"
                        size="xs"
                        mx={2}
                        textDecoration="underline"
                        margin="0"
                      >
                        {dict("ReadMoreLinkText")}
                      </Button>
                    </Link>
                  ),
                },
              ])
            : "-"}
        </Text>
      </Box>
    </HStack>
  );

  const name = "PaymentInputModel.Id";

  useEffect(() => {
    setError(null);
  }, [isOpen]);

  useEffect(() => {
    if (error) {
      onOpen();
      console.log("error?");
    }
  }, [error]);

  if (!paymentOptions.length) return <Spinner />;

  return (
    <Box pt="10">
      <CheckoutStep
        {...{ Step: "3", Heading: dict("HeadingSelectPaymentMethod") }}
      />
      <Box px={{ base: 2, md: 14 }} py={4}>
        {error ? (
          <ChsAlert
            isOpen={isOpen}
            onClose={onClose}
            buttonLink={Urls.CheckoutAddress}
          />
        ) : null}
        <Box>
          <Field name={name}>
            {({ field, form }: FieldProps) => {
              const { onChange, value, ...rest } = field;
              return (
                <FormControl
                  id={name}
                  isInvalid={!!form.errors[name] && !!form.touched[name]}
                >
                  <RadioGroup {...rest} id={name} size="lg" value={value}>
                    <VStack align="left">
                      {paymentOptions.map((option) => (
                        <Flex
                          key={option.Name}
                          justifyContent="space-between"
                          width="100%"
                          border={"1px"}
                          sx={{
                            label: {
                              width: "100%",
                              ".chakra-radio__label": {
                                width: "100%",
                              },
                            },
                          }}
                        >
                          <Radio
                            px={3}
                            py={3}
                            onChange={onChange}
                            value={option.Id.toString()}
                          >
                            <PaymentOption {...option} />
                          </Radio>
                        </Flex>
                      ))}

                      {error ? (
                        <></>
                      ) : (
                        <Modal onClose={onClose} size="3xl" isOpen={isOpen}>
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>{modalTitle}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                              <RichTextComponent text={modalText} />
                            </ModalBody>
                            <ModalFooter></ModalFooter>
                          </ModalContent>
                        </Modal>
                      )}
                    </VStack>
                  </RadioGroup>
                </FormControl>
              );
            }}
          </Field>

          <Box paddingTop="8">
            <Field name="newsLetter">
              {({ form }) => (
                <Checkbox
                  id="newsLetter"
                  name="newsLetter"
                  onChange={(e) =>
                    setFieldValue("newsLetter", e.target.checked)
                  }
                >
                  {dictAddressForm("CheckboxNewsletterSignup")}
                </Checkbox>
              )}
            </Field>
          </Box>

          <Box paddingTop="3">
            <Field marginTop="10" name="terms" validate={validateTerms}>
              {({ form, field }) => (
                <FormControl
                  isInvalid={form.errors[field.name]}
                  isRequired={true}
                >
                  <Checkbox
                    id="terms"
                    name="terms"
                    onChange={(e) => setFieldValue("terms", e.target.checked)}
                  >
                    {replaceAll(dictAddressForm("CheckboxAcceptTerms"), [
                      {
                        replaceThis: "*termsLink*",
                        replacement: (
                          <Link href={termsLink?.Url || "#"} target="_blank">
                            <Button
                              variant="link"
                              size="xs"
                              mx={2}
                              textDecoration="underline"
                              margin="0"
                            >
                              {dictAddressForm("LinkTextTerms")}
                            </Button>
                          </Link>
                        ),
                      },
                      {
                        replaceThis: "*cookiesLink*",
                        replacement: (
                          <Link href={cookiesLink?.Url || "#"} target="_blank">
                            <Button
                              variant="link"
                              size="xs"
                              mx={2}
                              textDecoration="underline"
                              margin="0"
                            >
                              {dictAddressForm("LinkTextCookies")}
                            </Button>
                          </Link>
                        ),
                      },
                    ])}
                  </Checkbox>
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Box>

          <Box paddingTop="8">
            <Button
              isDisabled={!isValid || !dirty}
              isLoading={isSubmitting}
              variant={isValid ? "inverted" : "base"}
              type="submit"
              fontSize="14px"
              fontWeight={500}
              my={2}
              width={{ base: "100%" }}
              size="lg"
            >
              {dict("ButtonContinue")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CheckoutPaymentForm;
