import { Spinner } from "@chakra-ui/react";
import CheckoutAddressForm from "@components/Checkout/Forms/CheckoutAddressForm.component";
import CheckoutDeliveryForm, {
  DeliveryWeekViewModel,
} from "@components/Checkout/Forms/CheckoutDeliveryForm.component";
import CheckoutPaymentForm from "@components/Checkout/Forms/CheckoutPaymentForm.component";
import { getWeek } from "@hooks/getTime";
import { useAppData } from "@hooks/useAppData";
import useBasket from "@hooks/useBasket";
import CheckoutLayout from "@layouts/Checkout.layout";
import {
  BasketAddress,
  DeliverySupplier,
  PaymentOption,
  PaymentSupplier,
} from "@modules/Basket/Basket.model";
import { Form, Formik } from "formik";
import { useLocale } from "next-intl";
import { useEffect, useState } from "react";

function CheckoutAddressPage(props) {
  const {
    upsertAddress,
    upsertShipping,
    getDeliveryWeeks,
    getDeliveryMethods,
    getPaymentSuppliers,
    upsertPayment,
  } = useBasket();

  const [
    {
      basket,
      marketData: { CountryName, MarketId, CountryTwoLetterIsoCode },
    },
  ] = useAppData();
  const locale = useLocale();
  
  const [sameInvoice, setSameInvoice] = useState(true);

  const earlyDate = basket?.EarliestDeliveryDate;
  const [weeks, setDeliveryWeeks] = useState<DeliveryWeekViewModel["Weeks"]>(
    []
  );
  const [deliveryMethods, setDeliveryMethods] = useState<
    DeliverySupplier["DeliveryMethods"]
  >([]);
  const [initialDeliveryWeek, setInitialDeliveryWeek] = useState<string>();
  const [initialDeliveryMethod, setInitialDeliveryMethod] = useState<string>();
  const [deliveryMethod, setDeliveryMethod] = useState<string>();
  const [deliveryDate, setDeliveryDate] = useState<string>();
  const [paymentOptions, setPaymentOptions] = useState<PaymentOption[]>([]);

  useEffect(() => {
    if (!!earlyDate) {
      const fetchWeeks = async () => {
        const earliestWeek = getWeek(earlyDate);
        const weeks = await getDeliveryWeeks(earliestWeek);
        setDeliveryWeeks(weeks);
        if (weeks && weeks.length > 0) {
          setInitialDeliveryWeek(weeks[0].WeekNumber.toString());
          setDeliveryDate(weeks[0].WeekNumber.toString());
        }
      };
      fetchWeeks().catch(console.error);
    }
  }, [earlyDate]);

  useEffect(() => {
    const fetchDeliveryMethods = async () => {
      const methods = await getDeliveryMethods();
      setDeliveryMethods(methods.DeliveryMethods);
      setInitialDeliveryMethod(methods.DeliveryMethods[0]?.Id.toString());
      setDeliveryMethod(methods.DeliveryMethods[0]?.Id.toString());
    };
    fetchDeliveryMethods().catch(console.error);
  }, []);

  useEffect(() => {
    // console.log("deliveryMethod:" + deliveryMethod)
    const updateShipping = async () => {
      await upsertShipping({
        ShippingIds: deliveryMethod,
        DeliveryDate: deliveryDate,
      });
    };
    updateShipping().catch(console.error);
  }, [deliveryMethod]);

  useEffect(() => {
    const fetchPaymentSuppliers = async () => {
      const { Supplier, PaymentOptions }: PaymentSupplier =
        await getPaymentSuppliers();
      setPaymentOptions(PaymentOptions || []);
    };
    fetchPaymentSuppliers().catch(console.error);
  }, []);

  function updateDeliveryMethod(deliveryMethod: string) {
    setDeliveryMethod(deliveryMethod);
  }

  function removeUnchecked(obj) {
    if (sameInvoice) {
      delete obj.VatNum;
      delete obj.SeperateInvoiceAddress;
    };
    delete obj.terms;
    return obj;
  }

  function removeEmpty(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v !== "")
      .reduce(
        (acc, [k, v]) => ({
          ...acc,
          [k]: v === Object(v) ? removeEmpty(v) : v,
        }),
        {}
      );
  }

  interface UpsertAddressInputModel {
    VatNum?: string;
    DeliveryAddress?: BasketAddress;
    SeperateInvoiceAddress?: BasketAddress;
    BasketId?: string;
    MarketId?: string;
  }

  interface UpsertShippingInputModel {
    ShippingIds?: string;
    DeliveryDate?: string;
  }

  interface UpsertPaymentInputModel {
    Id?: string;
  }

  const AddressInputModel: UpsertAddressInputModel = {
    VatNum: "",
    DeliveryAddress: {
      Name: "",
      Address1: "",
      Address1Furigana: "",
      ZipCode: "",
      City: "",
      Country: CountryName,
      PhoneNumber: "",
      Email: "",
    },
    SeperateInvoiceAddress: {
      Name: "",
      Address1: "",
      Address1Furigana: "",
      ZipCode: "",
      City: "",
      Country: CountryName,
      PhoneNumber: "",
      Email: "",
    },
  };
  const ShippingInputModel: UpsertShippingInputModel = {
    ShippingIds: initialDeliveryMethod || "",
    DeliveryDate: initialDeliveryWeek || "",
  };

  const PaymentInputModel: UpsertPaymentInputModel = {
    Id: paymentOptions.length ? paymentOptions[0].Id.toString() : "",
  };

  const initialValues = {
    AddressInputModel,
    ShippingInputModel,
    PaymentInputModel,
  };
  // useEffect(() => {
  //   console.log("initial values")
  //   console.log(initialValues)
  // }, []);

  function sendEmail(data: {
    FirstName: string;
    LastName: string;
    Email: string;
    CustomerCountry: string;
  }) {
    const newData = {
      FirstName: data.FirstName,
      LastName: data.LastName,
      Email: data.Email,
      CustomerTypes: "",
      CustomerCountry: data.CustomerCountry,
      Culture: locale,
      Source: "signup",
      MarketId: MarketId,
    };
    fetch("/api/signup", {
      method: "PUT",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    })
      .then((res) => {
        console.log("Response received");
        if (res.status === 200) {
          console.log("Response succeeded!");
        }
      })
      .catch((error) => {
        console.log("An error occured: ", error);
      });
  }

  function splitStringOnLastSpace(inputString: string) {
    // Find the index of the last space
    const lastSpaceIndex = inputString.lastIndexOf(" ");

    // Check if there is a space in the string
    if (lastSpaceIndex !== -1) {
      // Split the string into two parts using substring or slice
      const firstPart = inputString.substring(0, lastSpaceIndex);
      const secondPart = inputString.substring(lastSpaceIndex + 1);

      // Return an array with the two parts
      return [firstPart, secondPart];
    } else {
      // If there is no space, return the original string
      return [inputString];
    }
  }
  return (
    <CheckoutLayout {...props}>
      {!initialDeliveryWeek ||
      !initialDeliveryMethod ||
      !paymentOptions.length ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={initialValues}
          validateOnChange
          onSubmit={async (
            values: {
              AddressInputModel: UpsertAddressInputModel;
              ShippingInputModel: UpsertShippingInputModel;
              PaymentInputModel: UpsertPaymentInputModel;
              newsLetter: boolean;
            },
            actions
          ) => {
            // console.log("submit values")
            // console.log(values)

            const checkedValues = await removeUnchecked(
              values.AddressInputModel
            );
            const cleanValues = await removeEmpty(checkedValues);

            const selectedOption = paymentOptions.find(
              (item) => item.Id === parseInt(values.PaymentInputModel.Id)
            );

            setTimeout(async () => {
              actions.setSubmitting(true);

              if (values.newsLetter) {
                const name = splitStringOnLastSpace(
                  values.AddressInputModel.DeliveryAddress.Name
                );
                sendEmail({
                  FirstName: name[0],
                  LastName: name.length > 1 ? name[1] : "",
                  Email: values.AddressInputModel.DeliveryAddress.Email,
                  CustomerCountry: CountryTwoLetterIsoCode,
                });
              }

              const resAddress = await upsertAddress(cleanValues);

              const resShipping =
                values.ShippingInputModel.ShippingIds &&
                values.ShippingInputModel.DeliveryDate &&
                (await upsertShipping({
                  ShippingIds: values.ShippingInputModel.ShippingIds,
                  DeliveryDate: values.ShippingInputModel.DeliveryDate,
                }));

              const externalPaymentUrl =
                values.PaymentInputModel.Id &&
                selectedOption.PaymentProviderEndpoint &&
                (await upsertPayment({
                  PaymentId: values.PaymentInputModel.Id.toString(),
                  endpointId: selectedOption.PaymentProviderEndpoint,
                }));

              actions.setSubmitting(false);

              if (!!resAddress && !!resShipping && !!externalPaymentUrl)
                window.open(externalPaymentUrl, "_self"); //This is Altapay, GMO happens in useBasket
            }, 1000);
          }}
        >
          {({ handleSubmit, isValid, dirty, isSubmitting, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <CheckoutAddressForm {...props} 
                  setFieldValue={setFieldValue}
                  sameInvoice={sameInvoice}
                  setSameInvoice={setSameInvoice} />
                <CheckoutDeliveryForm
                  {...props}
                  weeks={weeks}
                  deliveryMethods={deliveryMethods}
                  updateDeliveryMethod={updateDeliveryMethod}
                  updateDeliveryDate={setDeliveryDate}
                />
                <CheckoutPaymentForm
                  {...props}
                  isValid={isValid}
                  dirty={dirty}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  paymentOptions={paymentOptions}
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </CheckoutLayout>
  );
}

export default CheckoutAddressPage;
