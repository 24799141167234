import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import RichTextComponent from "@components/RichText.component";
import getPriceFormat from "@hooks/getPriceFormat";
import { useAppData } from "@hooks/useAppData";
import { DeliveryMethod } from "@modules/Basket/Basket.model";
import { Field, FieldProps } from "formik";
import { useTranslations } from "next-intl";
import React, { Dispatch, SetStateAction, useState } from "react";

import { CheckoutStep } from "../CheckoutStep.component";

export interface DeliveryFormModel {
  ShippingIds?: string[];
  DeliveryDate?: string;
}

export interface Week {
  WeekNumber?: number;
  StartDate?: string;
  EndDate?: string;
}

export interface DeliveryWeekViewModel {
  Weeks?: Week[];
}

interface CheckoutDeliveryFormProps {
  weeks: Week[];
  deliveryMethods: DeliveryMethod[];
  updateDeliveryMethod: (deliveryMethod: string) => void;
  updateDeliveryDate: Dispatch<SetStateAction<string>>;
}

function CheckoutDeliveryForm({
  weeks,
  deliveryMethods,
  updateDeliveryMethod,
  updateDeliveryDate,
}: CheckoutDeliveryFormProps) {
  const [
    {
      marketData: { Currency },
    },
  ] = useAppData();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const dict = useTranslations("CheckoutDeliveryPage");
  const handleDeliveryMethodModalClick = (
    ReadMoreModalTitle: string,
    ReadMoreModalText: string
  ) => {
    setModalTitle(ReadMoreModalTitle);
    setModalText(ReadMoreModalText);
    onOpen();
  };
  function replaceAll(string: string, replacements: string | any[]) {
    if (replacements.length === 0) {
      return string;
    }

    const { replaceThis, replacement } = replacements[0];
    const parts = string.split(replaceThis);

    if (parts.length === 1) {
      return parts[0];
    }

    const replacedText = parts
      .map((part, index) => (
        <React.Fragment key={index}>
          {replaceAll(part, replacements.slice(1))}
          {index !== parts.length - 1 && replacement}
        </React.Fragment>
      ))
      .filter(Boolean);

    return <>{replacedText}</>;
  }
  const DeliveryOption = (method: DeliveryMethod) => (
    <SimpleGrid columns={4}>
      <GridItem colSpan={3} px={3}>
        <Text fontSize="lg" fontWeight="bold">
          {method.Name}
        </Text>
        <Text>
          {method.Description
            ? replaceAll(method.Description, [
                {
                  replaceThis: "*readMoreLink*",
                  replacement: (
                    <Link
                      onClick={() =>
                        handleDeliveryMethodModalClick(
                          method.ReadMoreModalTitle,
                          method.ReadMoreModalText
                        )
                      }
                    >
                      <Button
                        variant="link"
                        size="xs"
                        mx={2}
                        textDecoration="underline"
                        margin="0"
                      >
                        {dict("ReadMoreLinkText")}
                      </Button>
                    </Link>
                  ),
                },
              ])
            : "-"}
        </Text>
      </GridItem>
      <GridItem colSpan={1}>
        <Text fontSize="xl" float="right">
          {getPriceFormat(method.Price || "-", Currency)}
        </Text>
      </GridItem>
    </SimpleGrid>
  );

  const WeekOption = ({ WeekNumber, StartDate, EndDate }: Week) => (
    <>{`${dict("TextWeekPrefix")} ${WeekNumber} (${StartDate} - ${EndDate})`}</>
  );

  const name = "ShippingInputModel.ShippingIds";
  const name2 = "ShippingInputModel.DeliveryDate";

  return (
    <Box pt="10">
      <CheckoutStep
        {...{ Step: "2", Heading: dict("HeadingSelectDeliveryMethod") }}
      />
      <Box px={{ base: 2, md: 14 }} py={4}>
        <Field name={name}>
          {({ field, form }: FieldProps) => {
            const { onChange, value, ...rest } = field;

            return (
              <FormControl
                id={name}
                isInvalid={!!form.errors[name] && !!form.touched[name]}
              >
                <RadioGroup {...rest} id={name} size="lg" value={value}>
                  <VStack align="left">
                    {deliveryMethods.map((value) => (
                      <Flex
                        justifyContent="space-between"
                        width="100%"
                        border={"1px"}
                        key={value.Id}
                        sx={{
                          label: {
                            width: "100%",
                            ".chakra-radio__label": {
                              width: "100%",
                            },
                          },
                        }}
                      >
                        <Radio
                          px={3}
                          py={3}
                          onChange={(e) => {
                            updateDeliveryMethod(e.currentTarget.value);
                            onChange(e);
                          }}
                          value={value.Id.toString()}
                        >
                          <DeliveryOption {...value} />
                        </Radio>
                      </Flex>
                    ))}
                    <Modal onClose={onClose} size="3xl" isOpen={isOpen}>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>{modalTitle}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          <RichTextComponent text={modalText} />
                        </ModalBody>
                        <ModalFooter></ModalFooter>
                      </ModalContent>
                    </Modal>
                  </VStack>
                </RadioGroup>
              </FormControl>
            );
          }}
        </Field>
        <Field name={name2}>
          {({ field, form }: FieldProps) => {
            const { onChange, ...rest } = field;
            return (
              <FormControl
                id={name2}
                isInvalid={!!form.errors[name2] && !!form.touched[name2]}
              >
                <FormLabel htmlFor={name2}>
                  <Text fontSize="lg" pt={4}>
                    {dict("HeadingSelectDeliveryDate")}
                  </Text>
                </FormLabel>
                {weeks?.length && (
                  <Select
                    name={name2}
                    id="deliveryweek"
                    onChange={(e) => {
                      updateDeliveryDate(e.currentTarget.value);
                      onChange(e);
                    }}
                    borderColor="blackAlpha"
                    focusBorderColor="blackAlpha"
                    variant="outline"
                    borderRadius={0}
                  >
                    {weeks.map((value) => (
                      <option
                        key={value.WeekNumber}
                        value={value.WeekNumber.toString()}
                      >
                        <WeekOption {...value} />
                      </option>
                    ))}
                  </Select>
                )}
              </FormControl>
            );
          }}
        </Field>
      </Box>
    </Box>
  );
}

export default CheckoutDeliveryForm;
