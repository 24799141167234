import { Box, Container, Flex, useBreakpointValue } from "@chakra-ui/react";
import { EnvironmentBlockType } from "@components/Blocks/EnvironmentBlock/EnvironmentBlock.model";
import Image from "@components/Image/Image.component";
import ProductTeaser from "@components/ProductList/ProductTeaser.component";

function EnvironmentBlockComponent(data: EnvironmentBlockType) {
  if (!data.Products) return;
  const isMobile = useBreakpointValue({ base: true, md: false });
  // Now that image comes as an object, we can work to remove this adapter. Sku: Id might be the last problem
  const formatProducts =
    data?.Products.map((product) => ({
      ...product,
      Sku: product.Id,
      Configuration:
        product.Configuration instanceof Array
          ? product.Configuration
          : [product.Configuration],
      Image: product.Image,
    })) || [];
  return (
    <Container py={16} className={data.Kind}>
      <Flex
        justifyContent="space-between"
        flexDirection={{
          base: data.IsEnvironmentImageRightAligned ? "column" : "column",
          md: data.IsEnvironmentImageRightAligned ? "row" : "row-reverse",
        }}
        gap={8}
      >
        <Box width="100%" height="100%" flex={2}>
          {data.EnvironmentImage ? (
            <Image
              src={data.EnvironmentImage.Url}
              aspectRatio="16:9"
              alt={data.EnvironmentImage.AlternativeText}
              sizes="(max-width: 768px) 100vw,
                     (max-width: 992px) 66vw,
                     900px"
              objectFit="cover"
            />
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1} display="block" gap={4}>
          <ProductTeaser
            {...formatProducts[0]}
            sideBySide
            ImageSize="square"
            sizes="200px"
            flip={
              isMobile
                ? false
                : data.IsEnvironmentImageRightAligned
                ? false
                : true
            }
          />
          <ProductTeaser
            {...formatProducts[1]}
            sideBySide
            ImageSize="square"
            sizes="200px"
            flip={
              isMobile
                ? false
                : data.IsEnvironmentImageRightAligned
                ? false
                : true
            }
          />
        </Box>
      </Flex>
    </Container>
  );
}

export default EnvironmentBlockComponent;
