import { Box, Container, Heading, Text } from "@chakra-ui/react";
import HeroBlock from "@components/Blocks/HeroBlock";
import getColor from "@hooks/getColor";

import { StudioProductPageType } from "./StudioProductPage.model";

function StudioProductPageComponent({
  Heading: heading,
  Description,
  Hero,
  Kind,
  slug,
}: StudioProductPageType) {
  const bgColor = getColor(Kind, slug);

  return (
    <Box bg={bgColor}>
      <Container paddingY={{ base: 0, md: 20 }}>
        <Heading
          as="h1"
          size="lg"
          width={{ base: "100%", md: "50%" }}
          padding={{ base: 4, md: 0 }}
        >
          {heading}
        </Heading>
        <Text
          marginY={{ base: 2, md: 8 }}
          width={{ base: "100%", md: "50%" }}
          paddingX={{ base: 4, md: 0 }}
        >
          {Description}
        </Text>
        <HeroBlock {...Hero} variant="Studio" priority />
      </Container>
    </Box>
  );
}

export default StudioProductPageComponent;
