import { Box, Text } from "@chakra-ui/react";
import { ReactElement } from "react";

interface MissingBlockType {
  kind: string;
}

const containerStyles = {
  padding: 2,
  marginY: 2,
  fontSize: "xs",
  bg: "orange.100",
};

function MissingBlock({ kind }: MissingBlockType): ReactElement {
  return (
    <Box {...containerStyles}>
      <Text>Missing block:&nbsp;</Text>
      <Text color="red">{kind}</Text>
    </Box>
  );
}

export default MissingBlock;
