import { useAppData } from "@hooks/useAppData";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { PageBuilderContextType } from "./PageBuilder.model";

const PageBuilderContext = React.createContext<
  [PageBuilderContextType, Dispatch<SetStateAction<PageBuilderContextType>>]
>([{ data: null }, () => null]);

function PageBuilderProvider({
  children,
  data,
}: PropsWithChildren<PageBuilderContextType>) {
  const [state, dispatch] = useState<PageBuilderContextType>({
    data,
  });
  return (
    <PageBuilderContext.Provider value={[state, dispatch]}>
      {children}
    </PageBuilderContext.Provider>
  );
}

export { PageBuilderProvider, PageBuilderContext };
