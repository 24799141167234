import { Box, Flex, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import { GenericCarousel } from "@components/GenericCarousel";
import { CloudinaryNextImage } from "@components/Image";
import ProductTeaserInfo from "@components/ProductList/ProductTeaserInfo.component";
import { Splide } from "@splidejs/react-splide";
import { ReactElement, useRef } from "react";

import type { HighlightedProductsBlockType } from "./HighlightedProductsBlock.model";

function HighlightedProductsBlockComponent(
  data: HighlightedProductsBlockType
): ReactElement {
  const { VariantsWithImages } = data;
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const ProductTeaserAdapter = (content) => ({
    ...content,
    FormattedConfiguration: content.Configuration?.toString(),
    Image: content.VariantImage,
    PageUrl: content.VariantPageUrl,
    Title: content.Name,
    hidePrice: false,
  });

  const splideRef = useRef<Splide>(null);

  return (
    <Flex
      direction="row"
      height="100vh"
      minHeight="600px"
      className={data.Kind}
    >
      <Flex
        height="100%"
        width="100%"
        direction="column"
        justifyContent="center"
        alignItems="center"
        bg="brand.gray.300"
      >
        <Box width={{ base: "80%", md: "60%" }}>
          <GenericCarousel
            controls
            position={{ bottom: 0, right: 0 }}
            onMove={(splide, index) => {
              if (splideRef?.current) {
                splideRef.current.go(index);
              }
            }}
          >
            {VariantsWithImages?.map((content, idx) => {
              return (
                <SimpleGrid columns={1} gap={0} key={idx}>
                  <Box height="400px" position="relative">
                    <CloudinaryNextImage
                      src={content.VariantImage.Url}
                      alt={content.VariantImage.AlternativeText}
                      sizes="(max-width: 768px) 66vw,
                            500px"
                      layout="fill"
                      objectFit="contain"
                      objectPosition="bottom"
                    />
                  </Box>
                  <ProductTeaserInfo
                    {...ProductTeaserAdapter(content)}
                    centerText
                  />
                </SimpleGrid>
              );
            })}
          </GenericCarousel>
        </Box>
      </Flex>

      {isDesktop && (
        <Box as="figure" height="100%" width="100%" position="relative">
          <GenericCarousel
            options={{ autoplay: false, drag: false, pagination: false }}
            ref={splideRef}
          >
            {VariantsWithImages?.map((content, idx) => {
              return (
                <Box
                  height="100vh"
                  position="relative"
                  key={content.InSituImage?.Url || idx}
                >
                  <CloudinaryNextImage
                    src={content.InSituImage.Url}
                    alt={content.InSituImage.AlternativeText}
                    layout="fill"
                    objectFit="cover"
                    sizes="(max-width: 1280px) 50vw, 900px"
                  />
                </Box>
              );
            })}
          </GenericCarousel>
        </Box>
      )}
    </Flex>
  );
}

export default HighlightedProductsBlockComponent;
