import { Box, Heading, Text } from "@chakra-ui/react";
import Image from "@components/Image";

import type { ProcessItemType } from "./ProcessBlock.model";

export function ProcessItem({ Title, Image: image }: ProcessItemType) {
  return (
    <Box width="100%" height="100%" position="relative">
      <Image
        alt={image?.AlternativeText}
        src={image?.Url}
        sizes="200px"
        aspectRatio="1:1"
        objectFit="contain"
        quality={90}
      />

      <Heading as="h2" variant="heading4" marginY={4}>
        {Title}
      </Heading>
    </Box>
  );
}
