import { Link as Anchor, GridItem, Heading } from "@chakra-ui/react";
import NextLink from "next/link";

import Image from "../../Image";
import type { CategoryItemType } from "./CategoryBlock.model";

export function CategoryItem(data: CategoryItemType) {
  return (
    <GridItem paddingY={{ base: 8, md: 10 }} w="100%" h="100%">
      <NextLink href={data.Link?.Url || "#"} passHref>
        <Anchor>
          <Image
            src={data.Image?.Url}
            alt={data.Image?.AlternativeText}
            aspectRatio="3:4"
            sizes="(max-width: 1280px) 33vw,
                   600px"
            objectFit="cover"
          />
        </Anchor>
      </NextLink>
      {data.Link?.Title ? (
        <NextLink href={data.Link.Url || "#"} passHref>
          <Anchor>
            <Heading as="h2" size="sm" pt={4}>
              {data.Link.Title}
            </Heading>
          </Anchor>
        </NextLink>
      ) : null}
    </GridItem>
  );
}
