import { Link as Anchor, Box, Text } from "@chakra-ui/react";
import { StoreLocatorOverviewStoreType } from "@modules/StoreLocator/StoreLocator.model";
import { formatDistance } from "@modules/StoreLocator/StoreLocator.util";

function StoreLocatorCard(props: StoreLocatorOverviewStoreType) {
  const {
    properties: { name, distance, address, city, country, zip, phone, website },
  } = props;

  return (
    <Box textAlign="left">
      <Text fontWeight="bold" fontSize="xs">
        {name}
        {" - "}
        <Text as="span" fontWeight="normal" fontStyle="italic">
          {formatDistance(distance)}
        </Text>
      </Text>
      <Text as="address" fontSize="xs">
        {address}
        <br />
        {[city, country, zip].join(", ")}
      </Text>
      <Text fontSize="xs">
        <Anchor href={`tel://${phone}`}> {phone}</Anchor>
      </Text>
      <Text fontSize="xs">
        <Anchor href={website.url} fontSize="xs">
          {website.title}
        </Anchor>
      </Text>
    </Box>
  );
}

export default StoreLocatorCard;
