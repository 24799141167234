import { ReactComponent as FlagshipMarker } from "@assets/mapicons/flagship-marker.svg";
import { ReactComponent as StoreMarker } from "@assets/mapicons/store-marker.svg";
import { Grid, GridItem } from "@chakra-ui/react";
import MotionBoxComponent from "@components/MotionBox.component";
import getConfig from "@hooks/getConfig";
import bbox from "@turf/bbox";
import { BBox, distance } from "@turf/turf";
import { useAnimation } from "framer-motion";
import "mapbox-gl/dist/mapbox-gl.css";
import { useCallback, useEffect, useRef, useState } from "react";
import MapGL, { MapRef, Marker, Popup } from "react-map-gl";

import {
  StoreLocatorModel,
  StoreLocatorOverviewStoreType,
} from "./StoreLocator.model";
import StoreLocatorCard from "./StoreLocatorCard";
import StoreLocatorOverview from "./StoreLocatorOverview.component";

function StoreLocator({ data, viewportPosition }: StoreLocatorModel) {
  const { mapboxToken, mapboxStyle } = getConfig();
  const mapRef = useRef<MapRef>();
  const INITIAL_VIEW_STATE = {
    ...viewportPosition,
    zoom: 14,
    bearing: 0,
    pitch: 0,
  };

  const [toolTip, setToolTip] = useState<
    undefined | StoreLocatorOverviewStoreType
  >(undefined);

  const [stores, setStores] = useState([]);

  const handleFitBounds = ([minLng, minLat, maxLng, maxLat]: BBox) => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 50 }
      );
    }
  };

  const getClosest = useCallback(
    (location: [number, number]) => {
      const storesGeoJson = {
        type: "FeatureCollection",
        features: data.Stores.filter(
          (Store) =>
            !isNaN(Number(Store.Latitude)) && !isNaN(Number(Store.Longitude))
        ).map((Store) => {
          const latitude = Number(Store.Latitude);
          const longitude = Number(Store.Longitude);
          return {
            type: "Feature",
            properties: {
              name: Store.StoreName,
              address: Store.Address,
              isFlagship: Store.IsFlagship,
              phone: Store.Phone,
              zip: Store.Zip,
              country: Store.Country,
              city: Store.City,
              website: {
                title: Store.WebAddress.Title,
                url: Store.WebAddress.Url,
              },
              distance: 0,
              isHidden: false,
            },
            geometry: {
              type: "Point",
              coordinates: [longitude, latitude],
            },
          };
        }),
      };

      for (const store of storesGeoJson.features) {
        store.properties.distance = distance(
          location,
          store.geometry.coordinates
        );
      }
      storesGeoJson.features.sort(
        (a, b) => a.properties.distance - b.properties.distance
      );
      const newStores = storesGeoJson.features.slice(0, 300);

      const bounds = bbox({
        type: "FeatureCollection",
        features: newStores,
      });

      setStores(newStores);
      // handleFitBounds(bounds);
    },
    [data.Stores]
  );

  const jumpTo = (center: [number, number]): void => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.setCenter(center).setZoom(15);
    }
  };

  const tooltipAnimation = useAnimation();
  const handleTooltip = useCallback(
    (store) => {
      setTimeout(undefined);
      jumpTo(store.geometry.coordinates);
      setTimeout(() => {
        setToolTip(store);
      }, 100);
      tooltipAnimation.start({ opacity: [0, 1] });
    },
    [tooltipAnimation]
  );

  useEffect(() => {
    const { longitude, latitude } = viewportPosition;
    jumpTo([longitude, latitude]);
    getClosest([longitude, latitude]);
  }, [viewportPosition, getClosest]);

  return (
    <Grid
      templateColumns="1fr 4fr"
      flexDir="column-reverse"
      display={{ base: "flex", lg: "grid" }}
      height="100%"
    >
      <GridItem backgroundColor="white" width="380px" overflowY="scroll">
        <StoreLocatorOverview stores={stores} callback={handleTooltip} />
      </GridItem>
      <GridItem>
        <MapGL
          initialViewState={INITIAL_VIEW_STATE}
          mapboxAccessToken={mapboxToken}
          attributionControl={false}
          ref={mapRef}
          mapStyle={mapboxStyle}
          style={{ width: "100%", height: "50vh" }}
          onMoveEnd={(evt) =>
            getClosest([evt.viewState.longitude, evt.viewState.latitude])
          }
        >
          {typeof toolTip !== "undefined" && (
            <Popup
              offset={(toolTip.properties.isFlagship ? 50 : 40) + 5}
              longitude={toolTip.geometry.coordinates[0]}
              latitude={toolTip.geometry.coordinates[1]}
              anchor="bottom"
              closeButton={false}
              closeOnClick={true}
              onOpen={() => {
                tooltipAnimation.start({ opacity: [0, 1] });
              }}
              onClose={() => {
                setToolTip(undefined);
              }}
            >
              <MotionBoxComponent
                initial={{ opacity: 0 }}
                animate={tooltipAnimation}
              >
                <StoreLocatorCard {...toolTip} />
              </MotionBoxComponent>
            </Popup>
          )}

          {stores.map((store, index) => {
            const [longitude, latitude] = store.geometry.coordinates;
            const { isFlagship } = store.properties;
            const size = isFlagship ? 50 : 40;
            const Icon = isFlagship ? FlagshipMarker : StoreMarker;
            return (
              <Marker
                key={`store-${index}`}
                longitude={longitude}
                latitude={latitude}
                onClick={() => {
                  handleTooltip(store);
                }}
                anchor="bottom"
              >
                <Icon width={size} height={size} />
              </Marker>
            );
          })}
        </MapGL>
      </GridItem>
    </Grid>
  );
}

export default StoreLocator;
