import {
  Link as Anchor,
  Box,
  Container,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { ReactElement } from "react";

import type { InPageNavigationBlockType } from "./InPageNavigationBlock.model";

function InPageNavigationBlock(data: InPageNavigationBlockType): ReactElement {
  return (
    <Container position="relative" py="6" className={data.Kind}>
      <Grid templateColumns="repeat(12, 1fr)" gap={{ base: 2, md: 4 }}>
        {data.SiteItems?.map((site, key) => (
          <GridItem key={key} colSpan={{ base: 12, md: 4, lg: 2 }}>
            <Heading fontWeight={700} fontSize={["lg", null, null, "md"]}>
              <NextLink href={site.Link.Url} passHref>
                <Anchor>{site.Link.Title}</Anchor>
              </NextLink>
            </Heading>
            <Text>{site.Teaser}</Text>
          </GridItem>
        ))}
      </Grid>
    </Container>
  );
}

export default InPageNavigationBlock;
