import { SmallCloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading, IconButton } from "@chakra-ui/react";
import { useOnOutsideClick } from "@hooks/useOutsideClick";
import { useTranslations } from "next-intl";
import { MouseEventHandler } from "react";

interface Props {
  heading: string;
  handleOnClick: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}

export const SideBar = ({ heading, handleOnClick, children }: Props) => {
  // Close on outside click.
  const { innerBorderRef } = useOnOutsideClick(handleOnClick);
  const dict = useTranslations("Shared.Overlay");
  return (
    <Box
      position="fixed"
      boxShadow="2xl"
      borderColor="black"
      p={8}
      pb={16}
      right={0}
      top={0}
      bottom={0}
      overflow="scroll"
      zIndex={20}
      width={{ base: "100%", sm: "400px" }}
      bgColor="white"
      ref={innerBorderRef}
    >
      <Flex justifyContent="space-between" alignItems="center" pb={8}>
        <Heading size="lg" variant="title">
          {heading}
        </Heading>
        <IconButton
          borderRadius="50%"
          variant="icon"
          icon={<SmallCloseIcon />}
          onClick={handleOnClick}
          aria-label="Close filter view"
        />
      </Flex>

      {children}
      <Button onClick={handleOnClick} width="100%" mt={8}>
        {dict("ButtonClose")}
      </Button>
    </Box>
  );
};
