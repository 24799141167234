import {
  Link as Anchor,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { GenericCarousel } from "@components/GenericCarousel";
import Image from "@components/Image";

import { ProductDesignBlockType } from "./ProductDesignBlock.model";

function ProductDesignBlockComponent(data: ProductDesignBlockType) {
  const perPage = useBreakpointValue({ base: 2, sm: 3 });
  return (
    <Container className={data.Kind} my={8}>
      <Heading as="h2" size="xl" mb={4}>
        {data.Title}
      </Heading>
      {data.Images ? (
        <GenericCarousel
          perPage={perPage}
          perMove={1}
          controls
          ariaLabel="Gallery"
        >
          {data.Images?.map((image, i) => (
            <Box key={`${i} - ${image?.Url}`}>
              <Image
                alt={image?.AlternativeText}
                src={image?.Url}
                sizes="(max-width: 768px) 50vw,
                       (max-width: 1280px) 33vw,
                       450px"
                aspectRatio="1:1"
                objectFit="contain"
              />
              <Text>{image.Title}</Text>
            </Box>
          ))}
        </GenericCarousel>
      ) : (
        <></>
      )}
      <Grid templateColumns="repeat(12, 1fr)" gap={5}>
        <GridItem colSpan={{ base: 12, md: 4 }} my={4}>
          <Heading as="h3" size="md" mb={4}>
            Downloads
          </Heading>
          {data.Files ? (
            data.Files.map((file, i) => (
              <Anchor
                key={`${i} - ${file.Link?.Title}`}
                href={file.Link?.Url}
                download
              >
                <Flex justifyContent="space-between">
                  <Button variant="link">
                    <Text>{file.Description}</Text>
                  </Button>
                  <Text textTransform="uppercase" fontWeight="extrabold">
                    {file.Link?.Title}
                  </Text>
                </Flex>
              </Anchor>
            ))
          ) : (
            <></>
          )}
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 4 }} my={4}>
          <Heading as="h3" size="md" mb={4}>
            Materials
          </Heading>
          {data.Materials ? (
            data.Materials.map((mat, i) => (
              <Text key={`${i} - ${mat}`} fontSize="sm">
                {mat}
              </Text>
            ))
          ) : (
            <></>
          )}
        </GridItem>
      </Grid>
    </Container>
  );
}
export default ProductDesignBlockComponent;
