import {
  Link as Anchor,
  Box,
  Button,
  Container,
  GridItem,
  Heading,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import Image from "@components/Image";
import RichTextComponent from "@components/RichText.component";
import NextLink from "next/link";
import { ReactElement, useEffect, useRef, useState } from "react";

import type { BodyCopyTwoBlockType } from "./BodyCopyTwoBlock.model";

export function BodyCopyTwoBlock(data: BodyCopyTwoBlockType): ReactElement {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [parentBoxHeight, setParentBoxHeight] = useState(0);
  const parentBoxRef = useRef(null);
  const leftImageRef = useRef(null);
  const rightImageRef = useRef(null);

  useEffect(() => {
    const calculateHeight = () => {
      if (leftImageRef.current && rightImageRef.current) {
        const leftImageRect = leftImageRef.current.getBoundingClientRect();
        const rightImageRect = rightImageRef.current.getBoundingClientRect();

        // Calculate the total height based on the rendered sizes
        const leftHeight = leftImageRect.height * 0.667;
        const rightHeight = rightImageRect.height * 0.667;
        const totalHeight = leftHeight + rightHeight;
        setParentBoxHeight(totalHeight);
      }
    };

    calculateHeight(); // Initial calculation

    // Listen for changes in window size
    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  // Determine what aspect ratio to use, based on the image used in the component.
  const aspectRatio =
    data.LeftImage && data.LeftImage.Height > data.LeftImage.Width
      ? "3:4"
      : "16:9";

  // Wide image gets bigger width. This is to make the image bigger, as it becomes very small.
  let aspectWidth: string = ""; // Used to make images wider, if image is wide-format
  let aspectTop: string = ""; // used to give second image more spacing through "top", if image is wide-format
  let aspectHeight: number = 0; // used to give the image container more height, if the image is wide-format

  if (aspectRatio === "16:9") {
    aspectWidth = "75%";
    aspectTop = "40%";
    aspectHeight = 100;
  } else {
    aspectWidth = "66%";
    aspectTop = "25%";
    aspectHeight = 0;
  }

  return (
    <Container className={data.Kind}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={5}>
        <GridItem order={data.IsTextRightAligned ? 1 : "unset"} padding={4}>
          <Box
            display="flex"
            height="100%"
            // ensure that mobile always has "flex-start" and desktop switches between start and end
            // This is to ensure that text isn't hugging the edge on mobile
            justifyContent={
              isMobile
                ? "flex-start"
                : data.IsTextRightAligned
                ? "flex-start"
                : "flex-end"
            }
          >
            <Box alignSelf="center" maxW="100%">
              <Heading as="h2" size="lg">
                {data.Heading}
              </Heading>
              <Box marginY={8}>
                <RichTextComponent text={data.Body} />
              </Box>
              {data.Link && (
                <NextLink href={data.Link.Url} passHref>
                  <Anchor
                    display="flex"
                    alignItems="center"
                    sx={{ textDecoration: "none !important" }}
                  >
                    <Button variant="heroDark" mt={4} mb={10} mr={2}>
                      {data.Link.Title || data.Link.Url}
                    </Button>
                    {/* <ArrowForwardIcon /> */}
                  </Anchor>
                </NextLink>
              )}
            </Box>
          </Box>
        </GridItem>

        <GridItem alignSelf={"center"}>
          <Box
            position="relative"
            style={{ height: `${parentBoxHeight + aspectHeight}px` }}
            ref={parentBoxRef}
          >
            {data.LeftImage && (
              <Box
                position="absolute"
                width={aspectWidth}
                top="0"
                zIndex={1}
                // _hover={{ zIndex: 2 }}
                _hover={{ zIndex: 2, transform: "scale(1.05)" }}
                transition="all .35s ease"
                className="left-image"
                ref={leftImageRef}
              >
                <Image
                  src={data.LeftImage.Url}
                  alt={data.LeftImage.AlternativeText}
                  aspectRatio={aspectRatio}
                  sizes="33vw"
                />
              </Box>
            )}
            {data.RightImage && (
              <Box
                position="absolute"
                width={aspectWidth}
                top={aspectTop}
                right={0}
                zIndex={0}
                _hover={{ zIndex: 2, transform: "scale(1.05)" }}
                transition="all .35s ease"
                className="right-image"
                ref={rightImageRef}
              >
                <Image
                  src={data.RightImage.Url}
                  alt={data.RightImage.AlternativeText}
                  aspectRatio={aspectRatio}
                  sizes="33vw"
                />
              </Box>
            )}
          </Box>
        </GridItem>
      </SimpleGrid>
    </Container>
  );
}

export default BodyCopyTwoBlock;
