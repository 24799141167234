import {
  Link as Anchor,
  Box,
  Container,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";
import NextLink from "next/link";

import { ProductPageSpecsBlockType } from "./ProductPageSpecsBlock.model";

function ProductPageSpecsBlockComponent(data: ProductPageSpecsBlockType) {
  return (
    <Container className={data.Kind} my={8}>
      <Grid templateColumns="repeat(12, 1fr)" gap={5}>
        <GridItem colSpan={{ base: 12, lg: 6 }} my={4}>
          <Heading as="h2" size="xl" mb={4}>
            Materialer
          </Heading>
          <Text>{data.MaterialsDescription}</Text>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }} my={4}>
          <Heading as="h2" size="xl" mb={4}>
            Downloads
          </Heading>
          {data.ProductSheet?.Link?.Url && (
            <Box>
              <Text as="span" fontWeight="bold">
                {data.ProductSheet?.Description}
              </Text>
              {" | "}
              <NextLink href={data.ProductSheet?.Link.Url} passHref>
                <Anchor textDecoration="underline">
                  {data.ProductSheet?.Link.Title}
                </Anchor>
              </NextLink>
            </Box>
          )}
          {data.MaintenanceGuide?.Link?.Url && (
            <Box>
              <Text as="span" fontWeight="bold">
                {data.MaintenanceGuide?.Description}
              </Text>
              {" | "}
              <NextLink href={data.MaintenanceGuide?.Link.Url} passHref>
                <Anchor textDecoration="underline">
                  {data.MaintenanceGuide?.Link.Title}
                </Anchor>
              </NextLink>
            </Box>
          )}
          {data.OtherAssets.filter((asset) => asset.Link?.Url).map(
            (asset, i) => (
              <div key={i}>
                <Text as="span" fontWeight="bold">
                  {asset.Description}
                </Text>
                {" | "}(
                <NextLink href={asset.Link.Url} passHref>
                  <Anchor textDecoration="underline">{asset.Link.Title}</Anchor>
                </NextLink>
                )
              </div>
            )
          )}
        </GridItem>
      </Grid>
    </Container>
  );
}
export default ProductPageSpecsBlockComponent;
