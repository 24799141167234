import { Box, Text } from "@chakra-ui/react";
import { GenericCarousel } from "@components/GenericCarousel";
import Image from "@components/Image";
import { ImageCarouselType } from "@components/ImageCarousel/ImageCarousel.model";
import React from "react";

function ImageCarouselComponent({
  images,
  controls = false,
  perPage = 1,
  interval = 4000,
  aspectRatio = "16:9",
  objectFit = "cover",
  sizes,
  ...rest
}: ImageCarouselType) {
  if (!images?.length) return;
  return (
    <GenericCarousel
      perPage={perPage}
      interval={interval}
      controls={controls}
      position={{ bottom: 0, right: 0 }}
      ariaLabel="Gallery"
      pagination={images.length / perPage > 1}
      type={perPage > 1 ? "slide" : "fade"}
      {...rest}
    >
      {images.map((image, i) => (
        <Box key={i} position="relative">
          <Image
            alt={image?.AlternativeText}
            src={image?.Url}
            sizes={sizes || "50vw, 25vw"}
            aspectRatio={aspectRatio}
            size="large"
            objectFit={objectFit}
            focalPoint={image.FocalPoint}
          />
          <Text>{image?.Title}</Text>
        </Box>
      ))}
    </GenericCarousel>
  );
}

export default ImageCarouselComponent;
