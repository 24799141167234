import { GridItem, SimpleGrid, useEditable } from "@chakra-ui/react";
import ProductCarouselComponent from "@components/ProductList/ProductCarousel.component";
import { ProductListType } from "@components/ProductList/ProductList.model";
import ProductTeaser from "@components/ProductList/ProductTeaser.component";
import { useAppData } from "@hooks/useAppData";
import useRecommendations from "@hooks/useRecommendations";
import { useClientSideState } from "@zustand/clientSideState";
import { useRouter } from "next/router";
import { useEffect } from "react";

function ProductListComponent({
  columns = 12,
  products,
  helloRetailStrategy,
  helloRetailSplashFilter,
  isCarousel,
  hideBuyButton = false, // Give option to hide buy button from component
  showVariants = false,
}: ProductListType) {
  const {
    query: { slug },
  } = useRouter();

  const { priceListData, currentProductIds, currentCategoryIds, currentDesignerId } =
    useClientSideState();

  const [{ marketData }] = useAppData();

  const { Urls } = marketData;
  const router = useRouter();
  const localizedBasketName = Urls.CheckoutBasket.split("/")[3] || "basket";
  const isBasketPage = router.asPath.includes(localizedBasketName);

  const { recommend, finalProducts, error, loading } = useRecommendations({
    helloRetailRecomKey: helloRetailStrategy,
    defaultProducts: products,
    helloRetailSplashFilter: helloRetailSplashFilter,
  });
  useEffect(() => {
    if (priceListData) {
      // do not execute the recommend until pricelist has been async loaded
      if (!isBasketPage || currentProductIds) {
        recommend().catch(console.error);
      }
    }
  }, [slug, priceListData, currentProductIds, currentCategoryIds, currentDesignerId]);

  return isCarousel ? (
    <ProductCarouselComponent products={finalProducts} />
  ) : (
    <SimpleGrid as="ul" columns={columns} gap={4} listStyleType="none">
      {finalProducts &&
        finalProducts.map((product) => {
          return (
            <GridItem
              as="li"
              key={product.Sku}
              colSpan={
                product.ImageSize === "wide"
                  ? { base: 12, md: 6 }
                  : { base: 6, md: 4 }
              }
            >
              <ProductTeaser
                {...{ ...product }}
                sizes={product.ImageSize === "wide" ? "600px" : "300px"}
                hideBuyButton={hideBuyButton}
                showVariants={showVariants}
              />
            </GridItem>
          );
        })}
    </SimpleGrid>
  );
}

export default ProductListComponent;
