import { Container, Heading, useBreakpointValue } from "@chakra-ui/react";
import { GenericCarousel } from "@components/GenericCarousel";

import { DesignerListBlockType } from "./DesignerListBlock.model";
import { DesignerListItem } from "./DesignerListItem";

function DesignerListBlockComponent({
  Heading: heading,
  Designers,
  Kind,
}: DesignerListBlockType) {
  const perPage = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 });
  return (
    <Container className={Kind}>
      <Heading marginBottom={12}>{heading}</Heading>
      <GenericCarousel
        perPage={perPage}
        controls
        ariaLabel="Gallery of Designers"
      >
        {Designers.map((designer, i) => (
          <DesignerListItem {...designer} key={i} />
        ))}
      </GenericCarousel>
    </Container>
  );
}
export default DesignerListBlockComponent;
