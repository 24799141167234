import {
  Container,
  GridItem,
  Heading,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import { CaseListItem } from "@components/Blocks/CaseListBlock/CaseListItem";
import { FavoritPageModel } from "@components/Pages/FavoritPage.model";
import { useAppData } from "@hooks/useAppData";
import useCases from "@hooks/useCases";
import useFavorites from "@hooks/useFavorites";
import useSearch from "@hooks/useSearch";
import SearchResultCard from "@modules/Search/SearchResultCard";
import { useLocale, useTranslations } from "next-intl";
import { useEffect } from "react";

function FavoritPage(props: FavoritPageModel) {
  const dict = useTranslations("FavouritesPage");
  const locale = useLocale();
  // We're using appData.favorites because data from useFavorites does not update
  const { loading, getFavorites } = useFavorites();
  const [appData] = useAppData();
  const { favorites } = appData;

  const {
    data: searchResults,
    loading: resultsLoading,
    searchBySku,
  } = useSearch(locale);
  const {
    data: casesResults,
    loading: casesLoading,
    fetchCases,
  } = useCases(locale);

  useEffect(() => {
    getFavorites().catch(console.error);
  }, []);

  useEffect(() => {
    if (favorites && Array.isArray(favorites)) {
      const cases = favorites
        .filter((item) => item.favoriteType === 1)
        .map((favorite) => favorite.favoriteId);
      fetchCases(cases).catch(console.error);
      const products = favorites
        .filter((item) => item.favoriteType === 2)
        .map((favorite) => favorite.favoriteId);
      if (products && products.length > 0)
        searchBySku(products).catch(console.error);
    }
  }, [favorites]);

  return (
    <Container>
      {resultsLoading || casesLoading || loading ? (
        <Heading as="h3" variant="heading2" mb={6}>
          {dict("ReceivingListMessage")}
          <Spinner ml={4} />
        </Heading>
      ) : (
        !searchResults?.length &&
        !casesResults?.length && (
          <Heading as="h3" variant="heading2" mb={6}>
            {dict("ListIsEmptyMessage")}
          </Heading>
        )
      )}
      {searchResults && searchResults.length > 0 && (
        <>
          <Heading as="h3" variant="heading2" mb={6}>
            {`${dict("ProductHeading")} (${searchResults.length})`}
          </Heading>
          <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} mb={20} gap={5}>
            {searchResults.map((variant) => (
              <GridItem
                key={variant?.id}
                colSpan={variant?.variantImageSize === "wide" ? 2 : 1}
              >
                <SearchResultCard
                  {...variant}
                  formattedLowestPrice={undefined}
                />
              </GridItem>
            ))}
          </SimpleGrid>
        </>
      )}
      {casesResults && casesResults.length > 0 && (
        <>
          <Heading as="h3" variant="heading2" mb={6}>
            {`${dict("CaseHeading")} (${casesResults.length})`}
          </Heading>
          <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} mb={20} gap={5}>
            {casesResults.map((cas) => (
              <GridItem key={cas?.CaseId}>
                <CaseListItem data={cas} landscape={false} />
              </GridItem>
            ))}
          </SimpleGrid>
        </>
      )}
    </Container>
  );
}

export default FavoritPage;
