import {
  Link as Anchor,
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  Heading,
  Spacer,
} from "@chakra-ui/react";
import ImageCarouselComponent from "@components/ImageCarousel/ImageCarousel.component";
import MotionBox from "@components/MotionBox.component";
import RichTextComponent from "@components/RichText.component";
import { useAnimation } from "framer-motion";
// import Link from "next/link";
import NextLink from "next/link";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useInViewRef } from "rooks";

import type { HeroMixedBlockType } from "./HeroMixedMediaBlock.model";

export function HeroMixedBlock(data: HeroMixedBlockType): ReactElement {
  const animationControls = useAnimation();

  const [inViewRef, inView] = useInViewRef();
  const titleRef = useRef(null);
  const [titleWidth, setTitleWidth] = useState(0);

  useEffect(() => {
    if (titleRef.current) {
      setTitleWidth(titleRef.current.offsetWidth);
    }
  });

  useEffect(() => {
    animationControls.start({
      opacity: [0, 1],
      translateY: ["100%", "0%"],
    });
  }, [data, inView]); // eslint-disable-line

  return (
    <Container ref={inViewRef} className={data.Kind} maxW="unset" px={0}>
      <Grid templateColumns="repeat(12, 1fr)">
        <GridItem
          colSpan={{ base: 12, lg: 6 }}
          gridRow={{ base: 2, lg: "unset" }}
        >
          <Box
            padding={{ base: 4, lg: 10 }}
            display="flex"
            justifyContent="center"
            alignItems={{ base: "flex-start", lg: "flex-end" }}
            height="100%"
            flexFlow="column nowrap"
            textAlign={{ base: "left", lg: "left" }}
          >
            <MotionBox
              initial={{ translateY: "100%", opacity: 0 }}
              animate={animationControls}
              pr={8}
              maxWidth="570px"
            >
              <Box
                display="flex"
                justifyContent={{ base: "flex-start", lg: "flex-end" }}
                width="100%"
              >
                <Box display="flex" flexDirection="column">
                  <Heading
                    ref={titleRef}
                    as={data.HeadingH1 ? "h1" : "h2"}
                    variant={data.HeadingH1 ? "heading1" : "heading2"}
                    // fontSize={{
                    //   base: !data.HeadingH1 ? "2xl" : "2xl",
                    //   lg: !data.HeadingH1 ? "6xl" : "2xl",
                    // }}
                  >
                    {data.Heading}
                  </Heading>
                  <Heading
                    maxWidth={titleWidth}
                    as="h3"
                    fontSize="md"
                    display="block"
                    textAlign="left"
                    paddingTop="16px"
                    overflowWrap="break-word"
                  >
                    {data.SubHeading}
                  </Heading>
                  <Box
                    maxWidth={titleWidth}
                    fontSize="sm"
                    display="block"
                    textAlign="left"
                    paddingTop="16px"
                    overflowWrap="break-word"
                  >
                    <RichTextComponent text={data.Teaser} />
                  </Box>
                </Box>
              </Box>
              {data.Link && (
                <NextLink href={data.Link.Url} passHref>
                  <Anchor
                    display="flex"
                    justifyContent={{ base: "flex-start", lg: "flex-end" }}
                    alignItems="center"
                    sx={{ textDecoration: "none !important" }}
                  >
                    <Button variant="heroDark" mt={4} mb={10}>
                      {data.Link.Title || data.Link.Url}
                    </Button>
                    {/* <ArrowForwardIcon /> */}
                  </Anchor>
                </NextLink>
                // <Link href={data.Link.Url}>
                //   {data.Link.Title || data.Link.Url}
                // </Link>
              )}
            </MotionBox>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }} position="relative">
          <Box
            marginBottom={7}
            float="right"
            width={{ base: "85vw", lg: "100%" }}
          >
            {data?.Images?.length > 0 && (
              <ImageCarouselComponent
                images={data.Images}
                sizes="(max-width: 992px) 100vw,
                       (max-width: 1280px) 50vw,
                       900px"
              />
            )}
          </Box>
        </GridItem>
      </Grid>
    </Container>
  );
}

export default HeroMixedBlock;
