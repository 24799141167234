import React from "react";

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: Function;
  children: JSX.Element;
};

export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children);
