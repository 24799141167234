import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  AccordionProps,
  Heading,
} from "@chakra-ui/react";

export const ChsAccordion = ({ children, ...rest }: AccordionProps) => {
  return (
    <Accordion my={6} defaultIndex={[0]} {...rest}>
      {children}
    </Accordion>
  );
};

interface ChsAccordionItemProps extends AccordionItemProps {
  children: React.ReactNode;
}

export const ChsAccordionItem = ({
  title,
  children,
  ...rest
}: ChsAccordionItemProps) => {
  return (
    <AccordionItem {...rest}>
      <h2>
        <AccordionButton py={4} pl={0} pr={1}>
          <Heading as="h2" size="md" flex="1" textAlign="left" fontWeight={700}>
            {title}
          </Heading>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={8} pt={0} px={0}>
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};
