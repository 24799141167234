import { Box, Container, Heading, Text } from "@chakra-ui/react";
import { GenericCarousel } from "@components/GenericCarousel";

import { ProductCarouselBlockType } from "./ProductCarouselBlock.model";
import { ProductCarouselItem } from "./ProductCarouselItem";

function ProductCarouselBlockComponent({
  Title,
  Heading: heading,
  SebHeading,
  ProductItems,
  Kind,
}: ProductCarouselBlockType) {
  return (
    <Box bgColor="studio.gray.300">
      <Container className={Kind}>
        <Text>{Title}</Text>
        <Heading variant="heading2" marginBottom={12}>
          {heading}
        </Heading>
        <Text marginBottom={12}>{SebHeading}</Text>
        <GenericCarousel perPage={4} controls ariaLabel="Carousel of Products">
          {ProductItems.map((product, i) => (
            <ProductCarouselItem {...product} key={i} />
          ))}
        </GenericCarousel>
      </Container>
    </Box>
  );
}
export default ProductCarouselBlockComponent;
