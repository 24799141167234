import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { FormField } from "@components/FormField.component";
import MotionBox from "@components/MotionBox.component";
import getColor, { getFGColor } from "@hooks/getColor";
import { Form, Formik } from "formik";
import { useAnimation } from "framer-motion";
import { useTranslations } from "next-intl";
import { ReactElement, useEffect, useState } from "react";
import { useInViewRef } from "rooks";

import type { StudioContactBlockType } from "./StudioContactBlock.model";

function StudioContactBlock({
  Heading: heading,
  Name,
  Email,
  Description,
  Cta,
  Kind,
}: StudioContactBlockType): ReactElement {
  const animationControls = useAnimation();
  const [inViewRef, inView] = useInViewRef();
  const validationDict = useTranslations("Shared.Validation");
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    animationControls.start({
      opacity: [0, 1],
      translateY: ["10%", "0%"],
    });
  }, [inView]); // eslint-disable-line

  function validateEmail(value) {
    let error;
    if (
      !value ||
      value === "" ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ) {
      error = Email.ErrorMessage || validationDict("ErrorInvalidEmail");
    }
    return error;
  }

  function validateName(value) {
    let error;
    if (!value || value === "") {
      error = Name.ErrorMessage || validationDict("ErrorEmptyField");
    }
    return error;
  }

  function validateDescription(value) {
    let error;
    if (!value || value === "" || value.length < 30) {
      error = Description.ErrorMessage || validationDict("ErrorEmptyField");
    }
    return error;
  }

  async function sendEmail(data) {
    fetch("/api/sendmail", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        console.log("Response received");
        if (res.status === 200) {
          console.log("Response succeeded!");
          setEmailSent(true);
        }
      })
      .catch((error) => {
        console.log("An error occured: ", error);
      });
  }
  const bgColor = getColor(Kind);
  const initialValues = {
    name: "",
    email: "",
    description: "",
  };
  return (
    <Box bgColor={bgColor} color={getFGColor(bgColor)}>
      <Container
        zIndex={1}
        py={10}
        ref={inViewRef}
        height="100%"
        width={{ base: "100%", md: "60%" }}
      >
        <MotionBox
          initial={{ translateY: "100%", opacity: 0 }}
          animate={animationControls}
          height="100%"
        >
          {!emailSent ? (
            <Formik
              initialValues={initialValues}
              validateOnChange
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  sendEmail(values);
                  actions.setSubmitting(false);
                  actions.resetForm({
                    values: initialValues,
                  });
                }, 1000);
              }}
            >
              {({ handleSubmit, isValid, dirty, touched, isSubmitting }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Heading mb={2}>{heading}</Heading>
                    <VStack spacing={2}>
                      <FormField
                        name="name"
                        type="name"
                        label={Name.Label}
                        placeholder={Name.Placeholder}
                        validate={validateName}
                        autoComplete="name"
                      />
                      <FormField
                        name="email"
                        type="email"
                        label={Email.Label}
                        placeholder={Email.Placeholder}
                        validate={validateEmail}
                        autoComplete="email"
                      />
                      <FormField
                        name="description"
                        type="text"
                        component={Textarea}
                        label={Description.Label}
                        placeholder={Description.Placeholder}
                        validate={validateDescription}
                      />
                      <br />
                      <Button
                        variant="inverted"
                        disabled={!isValid || !dirty}
                        isLoading={isSubmitting}
                        type="submit"
                        fontSize="14px"
                        fontWeight={500}
                      >
                        {Cta}
                      </Button>
                    </VStack>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <Flex justifyContent="center" alignItems="center" height="20rem">
              <Heading>Thank you!</Heading>
            </Flex>
          )}
        </MotionBox>
      </Container>
    </Box>
  );
}

export default StudioContactBlock;
