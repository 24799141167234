import getConfig from "@hooks/getConfig";
import axios from "axios";

const { mapboxToken } = getConfig(true);

class StoreLocatorGeocoderServce {
  private apiEndpoint = "https://api.mapbox.com/geocoding/v5";

  async query(query: string, endpoint = "mapbox.places", autocomplete = false) {
    try {
      const { data } = await axios.get(
        `${this.apiEndpoint}/${endpoint}/${query}.json?access_token=${mapboxToken}&autocomplete=${autocomplete}`
      );

      return data;
    } catch (error) {
      console.log(error.message); // eslint-disable-line no-console
    }
  }
}

export default StoreLocatorGeocoderServce;
