import { Box, Container, useBreakpointValue } from "@chakra-ui/react";
import MotionBox from "@components/MotionBox.component";
import { useAnimation } from "framer-motion";
import { ReactElement, useEffect } from "react";
import { useInViewRef } from "rooks";

import Image from "../../Image";
import type { HeroBlockType } from "./HeroBlock.model";
import HeroBlockCopyComponent from "./HeroBlockCopy.component";

function HeroBlockComponent(data: HeroBlockType): ReactElement {
  if (!data.Images[0]?.ImageDesktop) return;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isStudio = data.variant === "Studio";
  const imageDisplayType = isStudio
    ? "ImageDesktop"
    : isMobile
      ? "ImageMobile"
      : "ImageDesktop";
  const animationControls = useAnimation();

  const [inViewRef, inView] = useInViewRef();

  useEffect(() => {
    animationControls.start({
      opacity: [0, 1],
      translateY: ["100%", "0%"],
    });
  }, [inView]); // eslint-disable-line
  
  const { IsImageDark } = data.Images[0];

  return (
    <Box
      position="relative"
      minHeight="100%"
      maxHeight={isMobile ? "100vh" : "calc(100vh - 130px)"}
      overflow="hidden"
      ref={inViewRef}
      className={data.Kind}
    >
      {data.Images[0]?.ImageDesktop &&
        data.Images.map(
          (image, key) =>
            image[imageDisplayType] && (
              <Image
                src={image[imageDisplayType].Url}
                alt={image[imageDisplayType].AlternativeText}
                key={key}
                size="full"
                aspectRatio={isStudio ? "16:9" : isMobile ? "1:1" : "16:9"}
                sizes="(max-width: 1200px) 100vw,
                       1920px"
                objectFit={isStudio ? "contain" : "cover"}
                priority={data.priority}
                focalPoint={image[imageDisplayType]?.FocalPoint}
                aspectRatioStyle={isMobile ? { position: "relative" }: {position: "static" }}
              />
            )
        )}
      <Container
        position={isMobile ? "static" : "absolute"}
        paddingLeft={isStudio ? 10 : isMobile ? 4 : "20%"}
        paddingTop={isStudio ? 10 : isMobile ? 10 : 20}
        paddingBottom={isStudio ? 10 : isMobile ? 0 : 20}
        zIndex={1}
        top={0}
      >
        <MotionBox
          color={
            isMobile
              ? "brand.black"
              : IsImageDark
                ? "brand.white"
                : "brand.black"
          }
          initial={{ translateY: "100%", opacity: 0 }}
          animate={animationControls}
          sx={{ overflow: "hidden" }}
        >
          <HeroBlockCopyComponent {...data} />
        </MotionBox>
      </Container>
    </Box>
  );
}

export default HeroBlockComponent;
