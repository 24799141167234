import {
  Link as Anchor,
  Box,
  Container,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import RichTextComponent from "@components/RichText.component";
import { ReactElement } from "react";

import type { ShoppingBenefitsBlockType } from "./ShoppingBenefitsBlock.model";

export function ShoppingBenefitsBlock(
  data: ShoppingBenefitsBlockType
): ReactElement {
  return (
    <Container className={data.Kind}>
      <Grid templateColumns="repeat(12, 1fr)" gap={5}>
        <GridItem
          colSpan={{ base: 12, md: 8 }}
          colStart={{ base: 0, md: 3 }}
          textAlign="center"
          px={{ base: 4, md: 0 }}
        >
          <Box display="flex" justifyContent="space-around">
            <Heading fontSize="md" fontWeight="medium" as="span">
              {data.Usp1}
            </Heading>
            <Heading fontSize="md" fontWeight="medium" as="span">
              {data.Usp2}
            </Heading>
            <Heading fontSize="md" fontWeight="medium" as="span">
              {data.Usp3}
            </Heading>
          </Box>
          <Box marginY={8} fontSize="sm">
            <RichTextComponent text={data.Body} />
          </Box>
          <Heading fontSize="md" fontWeight="medium" as="span">
            {data.ContactHeader}
          </Heading>
          <Box
            display="flex"
            justifyContent="center"
            gap={6}
            flexWrap="wrap"
            fontSize="sm"
            pt={4}
          >
            <Anchor href={`tel:${data.PhoneNumber}`}>
              {data.PhoneLabel} {data.PhoneNumber}
            </Anchor>
            <Anchor href={`mailto:${data.EmailAddress}`}>
              {data.EmailLabel} {data.EmailAddress}
            </Anchor>
          </Box>
        </GridItem>
      </Grid>
    </Container>
  );
}

export default ShoppingBenefitsBlock;
