import parsePhoneNumber from "libphonenumber-js";

// parses phonenumber with the assumption that it could be a national phonenumber wrongfully prefixed with '+' by browser autocomplete
const parsePhonenumberWithPreference = (phoneNumberString, preferredCountry = "DK") => {
    if (phoneNumberString) {
        let phoneNumber = parsePhoneNumber(phoneNumberString, preferredCountry);
        if (phoneNumber && !phoneNumber.isValid() && phoneNumberString[0] !== "+") {
            phoneNumber = parsePhoneNumber("+" + phoneNumberString);
        }
        if (!phoneNumber) return emptyPhoneObject;
        return phoneNumber;
    }
    return emptyPhoneObject;
};
// parses phonenumber with the assumption that it could be a national phonenumber wrongfully prefixed with '+' by browser autocomplete
const handlePhoneValueChange = (newValue, previousValue, preferredCountry = "DK") => {
    if (!newValue) return newValue;
    const cleanNewValue = newValue.replace(/\D/g, "");
    const cleanPreviousValue = previousValue?.replace(/\D/g, "") || "";
    if (Math.abs(cleanNewValue.length - cleanPreviousValue.length) <= 1) {
        // this is incremental not paste or autofill
        return newValue;
    }
    // examine if the pasted value is missing an areacode
    if (cleanNewValue.length > cleanPreviousValue.length) {
        const hypotheticalPhone = parsePhonenumberWithPreference(newValue, preferredCountry);
        if (hypotheticalPhone.isValid()) {
            return hypotheticalPhone.formatInternational();
        }
    }
    if (cleanNewValue.length < cleanPreviousValue.length) {
        // deleting
    }
    return newValue;
};

const emptyPhoneObject = {
    isValid: function () {
        return false;
    },
};

export { handlePhoneValueChange, emptyPhoneObject };
export default parsePhonenumberWithPreference;
