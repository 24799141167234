import { Box, Container, Heading, SimpleGrid } from "@chakra-ui/react";
import { ReactElement } from "react";

import type { CategoryBlockType } from "./CategoryBlock.model";
import { CategoryItem } from "./CategoryItem";

function CategoryBlock(data: CategoryBlockType): ReactElement {
  return (
    <Box>
      <Container
        position="relative"
        px={{ base: 4, md: "unset" }}
        className={data.Kind}
      >
        <Heading pb={4} as="h2" size="md">
          {data.Heading}
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={4}>
          {data.CategoryItems?.map((item, i) => (
            <Box key={i}>
              <CategoryItem {...item} />
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}

export default CategoryBlock;
