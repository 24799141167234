import { Box, Container, Heading, Text } from "@chakra-ui/react";

import Image from "../../components/Image";
import { StudioStartPageType } from "./StudioStartPage.model";

function StudioStartPageComponent({
  HeroHeading,
  HeroDescription,
  HeroImage,
}: StudioStartPageType) {
  return (
    <Box position="relative">
      <Image
        src={HeroImage?.Url}
        alt={HeroImage?.AlternativeText}
        size="full"
        aspectRatio="16:9"
        sizes="(max-width: 1280px) 100vw, 1920px"
        objectFit="cover"
        priority
      />
      <Container
        maxW="container.xl"
        paddingY={20}
        position="absolute"
        top={0}
        zIndex={1}
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexFlow="column nowrap"
        color="white"
      >
        <Heading as="h1" variant="heading1">
          {HeroHeading}
        </Heading>
        <Text variant="large" marginY={10} mb={0}>
          {HeroDescription}
        </Text>
      </Container>
    </Box>
  );
}

export default StudioStartPageComponent;
