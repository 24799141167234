import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Link as Anchor,
  Box,
  Container,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { GenericCarousel } from "@components/GenericCarousel";
import MotionBox from "@components/MotionBox.component";
import { useAnimation } from "framer-motion";
import NextLink from "next/link";
import { ReactElement, useEffect, useState } from "react";
import { useInViewRef } from "rooks";

import type { ProcessBlockType } from "./ProcessBlock.model";
import { ProcessItem } from "./ProcessItem";

function StudioProcessBlockComponent(data: ProcessBlockType): ReactElement {
  const animationControls = useAnimation();
  const [selected, setSelected] = useState(0);
  const [inViewRef, inView] = useInViewRef();
  const cards = useBreakpointValue({ base: 2, sm: 3, md: 4, lg: 5 });

  useEffect(() => {
    animationControls.start({
      opacity: [0, 1],
      translateY: ["10%", "0%"],
    });
  }, [data, inView]); // eslint-disable-line

  return (
    <Box
      position="relative"
      width="100vw"
      bgColor="studio.night"
      className={data.Kind}
    >
      <Container
        maxW="container.xl"
        zIndex={1}
        ref={inViewRef}
        height="100%"
        pb={6}
      >
        <MotionBox
          color="white"
          initial={{ translateY: "100%", opacity: 0 }}
          animate={animationControls}
          height="100%"
        >
          {data.Type && (
            <Text color="studio.gray.500" mb={4}>
              {data.Type}
            </Text>
          )}
          {data.Heading ? (
            data.Link ? (
              <NextLink href={data.Link?.Url}>
                <Anchor>
                  <Heading as="h2" variant="heading2" mb={6}>
                    {data.Heading}
                  </Heading>
                </Anchor>
              </NextLink>
            ) : (
              <Heading as="h2" variant="heading2" mb={6}>
                {data.Heading}
              </Heading>
            )
          ) : (
            <></>
          )}
          {data.Link ? (
            <NextLink href={data.Link?.Url}>
              <Anchor display="flex" alignItems="center" mb={4}>
                <Text>{data.Link?.Title}</Text>
                <ArrowForwardIcon />
              </Anchor>
            </NextLink>
          ) : (
            <></>
          )}
          {data.Description && <Text mb={12}>{data.Description}</Text>}
          <GenericCarousel
            perPage={cards}
            perMove={1}
            controls
            ariaLabel="Process carousel"
            position={{ bottom: 0, right: 0 }}
          >
            {data.ProcessItems.map((item, i) => (
              <Box
                key={i}
                opacity={i !== selected ? "40%" : "unset"}
                onClick={() => setSelected(i)}
                _hover={{
                  filter: i === selected ? "saturate(100%)" : "saturate(50%)",
                }}
                transition="all 0.3s"
                p={4}
                filter={i === selected ? "saturate(100%)" : "saturate(0%)"}
              >
                <ProcessItem {...item} Description={data.Description} />
              </Box>
            ))}
          </GenericCarousel>
          <Text height="8rem">{data.ProcessItems[selected].Description}</Text>
        </MotionBox>
      </Container>
    </Box>
  );
}

export default StudioProcessBlockComponent;
