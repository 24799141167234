import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  GridItem,
  Heading,
  IconButton,
  SimpleGrid,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ChakraStylesConfig, MultiValue, Select } from "chakra-react-select";
import { useAppData } from "@hooks/useAppData";
import useFilter from "@hooks/useFilter";
import useTracking from "@hooks/useTracking";
import { SearchVariantModel } from "@modules/Search/Search.model";
import SearchResultCard from "@modules/Search/SearchResultCard";
import { useClientSideState } from "@zustand/clientSideState";
import { useLocale, useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { DeleteIcon, CloseIcon, SmallCloseIcon } from "@chakra-ui/icons"
import { FacetType, FilterPageType } from "./FilterPage.model";
import { useOnOutsideClick } from "@hooks/useOutsideClick";

function FilterPageComponent({
  BaseFilter,
  Facets: BaseFacets,
  Header,
  Category,
  Designer,
  OverwriteHelloRetailPagesApikey
}: FilterPageType) {
  const { priceListData } = useClientSideState();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const locale = useLocale();
  const [
    appData
  ] = useAppData();
  const {
    marketData: { MarketId, HelloRetailPagesApikey },
  } = appData;
  const helloRetailApikey = OverwriteHelloRetailPagesApikey || HelloRetailPagesApikey;
  const { trackViewList } = useTracking();
  const optionalBasefilter = BaseFilter || [""];

  const { search, data, error, loading } = useFilter({
    Culture: locale,
    MarketId: MarketId,
    Category: Category,
    Designer: Designer,
    Filters: optionalBasefilter,
    Facets: BaseFacets,
    HelloRetailPagesApikey: helloRetailApikey
  });

  const {
    query: { slug }
  } = useRouter();
  const router = useRouter();
  const facetDict = useTranslations("Facet");
  const filterDict = useTranslations("Filter");

  const removeAllFilters = () => {
    setFilters([]);
    setActiveAccordions([-1]);
  }

  const removeFilter = (filter: string) => {
    console.log("filter: " + JSON.stringify(filter))
    const clearIndex = filters.indexOf(filter);
    console.log(clearIndex)
    const newFilters = [...filters];
    console.log(newFilters)
    newFilters.splice(clearIndex, 1);
    console.log(newFilters);
    setFilters(newFilters);
  }

  const [filters, setFilters] = useState([]);

  const [showFilters, setShowFilters] = useState(false);

  const [originalFacetsList, setOriginalFacetsList] = useState<[string, FacetType[]][]>([]);

  const [activeAccordions, setActiveAccordions] = useState<number | number[]>([-1]);

  const dict = useTranslations("Shared.Overlay");

  const handleOnClick = () => {
    setShowFilters(!showFilters);
  };

  const { innerBorderRef } = useOnOutsideClick(handleOnClick);

  const handleFacet = (facet) => {
    if (filters.includes(facet.value)) {
      const clearIndex = filters.indexOf(facet.value);
      const newFilters = [...filters];
      newFilters.splice(clearIndex, 1);
      setFilters(newFilters);
    } else {
      setFilters([...filters, facet.value]);
    }
  };

  useEffect(() => {
    setFilters([]);
  }, [router.asPath])

  useEffect(() => {
    if (priceListData) {
      // do not execute the filter search until priselist has been async loaded
      search({ Filters: [...optionalBasefilter, ...filters] }).catch(
        console.error
      );
    }
  }, [slug, filters, priceListData]);

  useEffect(() => {
    if (data) {
      trackViewList(variants);
    }
  }, [data]);

  useEffect(() => {
    const handleRouteChange = () => {
      const path = window.location.pathname;
      sessionStorage.setItem("scrollPosition" + path, window.scrollY.toString());
      return true;
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange)
    }
  }, []);

  useEffect(() => {
    if (originalFacetsList.length == 0 && data) {
      setOriginalFacetsList(Object.entries(data.facets))
    }
  }, [data, originalFacetsList])

  useEffect(() => {
    router.beforePopState(() => {
      sessionStorage.setItem("isBack", "true")
      return true
    });

    const isBack = sessionStorage.getItem("isBack") == "true";
    if (data && isBack) {
      const path = window.location.pathname;
      const key = "scrollPosition" + path
      const sessionItem = Number(sessionStorage.getItem(key));
      window.scroll({
        top: sessionItem,
        behavior: "auto"
      });
      sessionStorage.setItem("isBack", "false");
    }
  }, [data]);

  const chakraStyles: ChakraStylesConfig = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "0px",
    }),
    container: (provided, state) => ({
      ...provided,
      marginRight: "5px"
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "black"
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 100,
      width: "max-content",
      minWidth: "100%"
    })
  };

  // @todo: Implement proper error handling.
  if (error) {
    return <Text>{error}</Text>;
  }

  if (!data) return;

  const { variants, facets } = data;

  return (
    <Container pt={0}>

      {showFilters && (
        sideBar()
      )}

      <Heading size="lg" variant="title">
        {Header}
      </Heading>
      <Flex alignItems="center" pt={4} pb={8}>
        {isMobile && (
          <Button onClick={handleOnClick}>
            {filters.length > 0 ? `${filterDict("ButtonFilterMenuMobile")} (${filters.length})` : filterDict("ButtonFilterMenuMobile")}
          </Button>
        )}
        {!isMobile && (
          facetNavigation()
        )}
        {/* {loading ? <Spinner ml={4} /> : <></>} */}
      </Flex>
      {!isMobile && (
        chosenFilters()
      )}
      {loading && (
        <Container pt={0}>
          <Spinner />
        </Container>
      )}
      {!loading && (resultGrid())}
    </Container>
  );

  function sideBar() {
    return (
      <Box
        position="fixed"
        boxShadow="2xl"
        borderColor="black"
        p={8}
        pb={16}
        right={0}
        top={0}
        bottom={0}
        overflow="scroll"
        zIndex={20}
        width={{ base: "100%", sm: "400px" }}
        bgColor="white"
        ref={innerBorderRef}
      >
        <Flex justifyContent="space-between" alignItems="center" pb={8}>
          <Heading size="lg" variant="title">
            {filterDict("ButtonFilterMenuMobile")}
          </Heading>
          <IconButton
            borderRadius="50%"
            variant="icon"
            icon={<SmallCloseIcon />}
            onClick={handleOnClick}
            aria-label="Close filter view"
          />
        </Flex>

        <Accordion index={activeAccordions} onChange={setActiveAccordions} allowMultiple>
          {facetNavigationMobile()}
        </Accordion>
        <Box mt={8}>
          <Button onClick={removeAllFilters} isDisabled={filters.length == 0} backgroundColor="#e53e3e" color="white" marginRight="5px" width="49%">   {filterDict("RemoveFiltersMobileLabel")} ({filters.length})</Button>
          <Button onClick={handleOnClick} width="49%">
            {dict("ButtonClose")}
          </Button>
        </Box>
      </Box>
    )
  }

  function chosenFilters() {
    if (filters.length == 0) return null;
    return (
      <>
        <Flex alignItems="center" pb={4}>
          <Heading size="sm" variant="title">
            {filterDict("ChosenFiltersLabel")}
          </Heading>
        </Flex>
        <Flex align="center" pb={8}>
          <Button marginRight="5px" onClick={removeAllFilters}>
            {filterDict("RemoveFiltersLabel")}
            <DeleteIcon marginLeft="5px" />
          </Button>
          {filters.map((filter) => {
            let filterTitle = filter;
            let parts = filter.split(":");
            if (parts.length > 0)
              filterTitle = parts[1];
            return (
              <Button marginRight="5px" onClick={() => removeFilter(filter)}>
                {filterTitle}
                <CloseIcon boxSize={3} marginLeft="5px" marginTop="3px" />
              </Button>
            )
          })}
        </Flex >
      </>
    )
  }

  function facetNavigation() {
    if (!facets || originalFacetsList.length == 0) return null;
    return Object.entries(facets).sort((a, b) => originalFacetsList.findIndex(facet => facet[0] === a[0]) - originalFacetsList.findIndex(facet => facet[0] === b[0])).map(([key, facets]) => {
      if (facets.length === 0) return null;
      const cleanKey = key.replace("extraData.", "");
      const selectPlaceholder = facetDict(
        cleanKey.charAt(0).toUpperCase() + cleanKey.slice(1)
      );
      const selectId = selectPlaceholder + "-select"
      return (
        <Box key={key}>
          <Select
            isMulti
            placeholder={selectPlaceholder}
            id={selectId}
            name={selectPlaceholder}
            closeMenuOnSelect={false}
            options={facets.map((item) => {
              let optionTitle = item.text;
              if (item.value.startsWith("hierarchies:"))
                // remove trailing $
                optionTitle = optionTitle.slice(0, -1);
              const parts = optionTitle.split("|");
              if (parts.length > 0) {
                optionTitle = parts[parts.length - 1];
                if (!optionTitle) optionTitle = parts[0];
              }
              return {
                value: item.value, label: `${optionTitle}`
              }
            })}
            isOptionSelected={(option: any) => {
              return filters.includes(option?.value) || optionalBasefilter.includes(option?.value)
            }}
            hideSelectedOptions={false}
            onChange={(item, action) => {
              handleFacet(action?.option)
            }}
            focusBorderColor="blackAlpha"
            chakraStyles={chakraStyles}
            useBasicStyles={true}
            selectedOptionStyle="check"
            colorScheme="black"
            controlShouldRenderValue={false}
            isClearable={false}
          />
        </Box>
      );
    });
  }

  function facetNavigationMobile() {
    if (!facets || originalFacetsList.length == 0) return null;
    let newFacets = Object.entries(facets);
    return originalFacetsList.map(([key, facets]) => {
      let newFacetIndex = newFacets.findIndex(x => x[0] == key);
      if (facets.length === 0) return null;
      const cleanKey = key.replace("extraData.", "");
      const heading = facetDict(
        cleanKey.charAt(0).toUpperCase() + cleanKey.slice(1)
      );
      return (
        <Box key={key}>
          <AccordionItem isDisabled={newFacetIndex == -1} border="none" bgColor="white">
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  {heading}
                </Box>
                {/* <Heading size="md" textAlign="center" py={4}>
                {heading}
              </Heading> */}
                <AccordionIcon />
              </AccordionButton>
            </h2>
            {newFacetIndex != -1 && (
              <SimpleGrid columns={{ base: 1, sm: 2 }} gap={4}>
                {newFacets[newFacetIndex][1].map((item) => {
                  let checkboxTitle = item.text;
                  if (item.value.startsWith("hierarchies:"))
                    // remove trailing $
                    checkboxTitle = checkboxTitle.slice(0, -1);
                  const parts = checkboxTitle.split("|");
                  if (parts.length > 0) {
                    checkboxTitle = parts[parts.length - 1];
                    if (!checkboxTitle) checkboxTitle = parts[0];
                  }
                  return (
                    <AccordionPanel paddingTop="0" paddingBottom="0" key={item.value}>
                      <Box>
                        <Checkbox
                          isChecked={
                            filters.includes(item.value) ||
                            optionalBasefilter.includes(item.value)
                          }
                          onChange={() => handleFacet(item)}
                        >
                          <Text fontSize="sm" casing="capitalize">
                            {checkboxTitle}
                          </Text>
                        </Checkbox>
                      </Box>
                    </AccordionPanel >
                  );
                })}
              </SimpleGrid>
            )}
          </AccordionItem>
        </Box >
      );
    });
  }


  function resultGrid() {
    if (!facets) return null;
    const showVariantDescription = filters.length > 0;
    let groupedData = new Map<string, SearchVariantModel[]>();
    variants.forEach((variant) => {
      const key = variant.model.designerId;
      if (!groupedData.has(key)) {
        groupedData.set(key, []);
      }
      groupedData.get(key).push(variant);
    });

    return Array.from(groupedData).map(([key, variants]) => {
      let designer = facets["extraData.designerName"].find(
        (designer) => designer.text == variants[0].model.designerName
      );
      return (
        <Box key={`${designer.value}`}>
          <Heading my={4}>{designer.text}</Heading>
          <SimpleGrid columns={{ base: 4, md: 6, lg: 6 }} mb={20} gap={5}>
            {variants.map((variant, idx) => (
              <GridItem
                key={variant.id}
                colSpan={variant.variantImageSize === "wide" ? 3 : 2}
              >
                <SearchResultCard {...variant} hidePrice isSearchPage={showVariantDescription} priority={idx < 4} />
              </GridItem>
            ))}
          </SimpleGrid>
        </Box>
      );
    });
  }
}
export default FilterPageComponent;
