import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Link as Anchor,
  Box,
  Container,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { GenericCarousel } from "@components/GenericCarousel";
import MotionBox from "@components/MotionBox.component";
import getColor, { getColorName, getFGColor } from "@hooks/getColor";
import { useAnimation } from "framer-motion";
import NextLink from "next/link";
import { ReactElement, useEffect } from "react";
import { useInViewRef } from "rooks";

import type { ExploreBlockType } from "./ExploreBlock.model";
import { ExploreItem } from "./ExploreItem";

function StudioExploreBlockComponent(data: ExploreBlockType): ReactElement {
  const animationControls = useAnimation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const cards = data.SmallCards
    ? useBreakpointValue({ base: 2, sm: 3, md: 4, lg: 5 })
    : useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 3 });
  const [inViewRef, inView] = useInViewRef();

  useEffect(() => {
    animationControls.start({
      opacity: [0, 1],
      translateY: ["10%", "0%"],
    });
  }, [data, inView]); // eslint-disable-line
  const bgOverrideName =
    data.BgColorOverride && `studio.${getColorName(data.BgColorOverride)}`;
  const bgColor = bgOverrideName || getColor(data.Kind, data.Link?.Url);
  const fgColor = getFGColor(bgColor);

  return (
    <Box bgColor={bgColor}>
      <Container className={data.Kind} width="100vw" zIndex={1} ref={inViewRef}>
        <MotionBox
          color={fgColor}
          initial={{ translateY: "100%", opacity: 0 }}
          animate={animationControls}
        >
          {data.Type && (
            <Text opacity="50%" mb={4}>
              {data.Type}
            </Text>
          )}
          {data.Heading && (
            <Heading variant="heading2" as="h2" mb={6}>
              {data.Heading}
            </Heading>
          )}
          {data.Description && (
            <Text width={isMobile ? "100%" : "50%"} color={fgColor} mb={6}>
              {data.Description}
            </Text>
          )}

          {data.Link && (
            <NextLink href={data.Link.Url} passHref>
              <Anchor display="flex" alignItems="center" my={6}>
                <Text variant="cta" mr={2}>
                  {data.Link.Title || data.Link.Url}
                </Text>
                <ArrowForwardIcon />
              </Anchor>
            </NextLink>
          )}
          <GenericCarousel
            perMove={1}
            controls
            ariaLabel={`Explore ${data.Type}`}
            perPage={cards}
          >
            {data.ExploreItems.map((item, i) => (
              <ExploreItem {...item} key={i} Ratio={data.Ratio} />
            ))}
          </GenericCarousel>
        </MotionBox>
      </Container>
    </Box>
  );
}

export default StudioExploreBlockComponent;
