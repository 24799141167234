import { Link as Anchor, Box, Flex, Text } from "@chakra-ui/react";
import AddBasketButton from "@components/AddBasketButton";
import ProductImage from "@components/ProductList/ProductImage.component";
import getPriceFormat from "@hooks/getPriceFormat";
import { useAppData } from "@hooks/useAppData";
import { useTranslations } from "next-intl";
import NextLink from "next/link";
import React from "react";

import { ProductInspirationVariantViewModel } from "./ProductInspirationBlock.model";

export const ProductInspirationItem = (
  data: ProductInspirationVariantViewModel
) => {
  const { Title, Images, FormattedPrice, Id, VariantPageUrl } = data;
  const dict = useTranslations("ProductList");
  const [
    {
      marketData: { Currency },
    },
  ] = useAppData();

  return (
    <Flex
      mt={4}
      justifyContent="end"
      alignItems={{ base: "flex-start", md: "flex-end" }}
      flexDirection={{ base: "column", md: "row" }}
    >
      <Box
        mr={4}
        order={{ base: "2", md: "1" }}
        textAlign={{ base: "left", md: "right" }}
      >
        <Text my={4}>
          <NextLink href={VariantPageUrl} passHref>
            <Anchor>{Title}</Anchor>
          </NextLink>
        </Text>
        <Text my={4} fontSize="sm" fontWeight="semibold">
          {getPriceFormat(FormattedPrice, Currency)}
        </Text>
        <AddBasketButton size="sm" Sku={Id} {...data} PurchaseNotEnabled />
        <NextLink href={VariantPageUrl} passHref>
          <Anchor fontSize="sm" pl={2}>
            {dict("LinkViewProduct")}
          </Anchor>
        </NextLink>
      </Box>
      <Box
        order={{ base: "1", md: "2" }}
        bg="brand.gray.300"
        width="200px"
        height="200px"
        boxShadow="lg"
      >
        <ProductImage
          image={Images[0]}
          format="square"
          sizes="200px"
          url={VariantPageUrl}
        />
      </Box>
    </Flex>
  );
};

export default ProductInspirationItem;
