import {
  Box,
  Button,
  Divider,
  Heading,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useAppData } from "@hooks/useAppData";
import useBasket from "@hooks/useBasket";
import useTracking from "@hooks/useTracking";
import CheckoutLayout from "@layouts/Checkout.layout";
import BasketLine from "@modules/Basket/BasketLine/BasketLine.component";
import { useClientSideState } from "@zustand/clientSideState";
import { useTranslations } from "next-intl";
import NextLink from "next/link";
import { useEffect, useState } from "react";

function BasketPage(pageData) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const dict = useTranslations("BasketPage");
  const [appData] = useAppData();
  const { trackBeginCheckout } = useTracking();
  const {
    basket,
    marketData: { Currency, Urls },
  } = appData;

  const { currentProductIds, setCurrentProductIds } = useClientSideState();
  const { getBasket, loading, checkSoldOutItems, isInvalidBasket } = useBasket();

  useEffect(() => {
    checkSoldOutItems(basket);
  }, [basket]);

  useEffect(() => {
    getBasket();
  }, []);

  useEffect(() => {
    if (basket && basket.Lines) {
      const variantIds = basket.Lines.map(function (v) {
        return v.VariantId;
      });
      if (JSON.stringify(currentProductIds) !== JSON.stringify(variantIds)) {
        setCurrentProductIds(variantIds)
      }
    }
  }, [basket]);

  const noItems = basket === null || !basket?.Lines;

  return (
    <CheckoutLayout basket>
      <Heading as="h1" pb={4}>
        {loading ? (
          <Spinner ml={4} />
        ) : (
          dict(noItems ? "HeadingBasketNoProducts" : "HeadingBasket")
        )}
      </Heading>
      {isMobile ? (
        <Box display="flex" gap={2} pb={4} justifyContent="center">
          <NextLink passHref href={Urls.CheckoutAddress || "/address"}>
            <Button
              variant="inverted"
              width={{ base: "100%" }}
              size="lg"
              onClick={() => trackBeginCheckout()}
              isDisabled={isInvalidBasket}
            >
              {dict("ButtonTextToCheckout")}
            </Button>
          </NextLink>
        </Box>
      ) : (
        <></>
      )}

      <Divider />
      {!noItems &&
        basket.Lines.map((line) => (
          <Box key={line.VariantId}>
            <BasketLine
              {...line}
              IsSoldOutText={dict("ProductSoldOutText")}
              RemoveSoldOutProductText={dict("RemoveSoldOutProductText")}
              removeText={dict("ButtonTextRemoveLine")}
              currency={Currency}
            />
            <Divider />
          </Box>
        ))}
    </CheckoutLayout>
  );
}

export default BasketPage;
