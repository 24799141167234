import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Link as Anchor,
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import Image from "@components/Image/Image.component";
import { useTranslations } from "next-intl";
import NextLink from "next/link";
import { ReactElement } from "react";

import type { FlagshipStoresCardType } from "./FlagshipStoresBlock.model";

export function FlagshipStoresCard(data: FlagshipStoresCardType): ReactElement {
  const dict = useTranslations("StoreLocator");
  const onMobile = useBreakpointValue({ base: true, sm: false });
  const { OpeningHours } = data;
  let hours = Array(4).fill("");
  if (OpeningHours) {
    for (const [i, value] of OpeningHours.entries()) {
      hours[i] = `${value.Description} ${value.TimeStartToEnd}`;
    }
  }

  if (!data.ImageUrl) return;
  return (
    <Box width="100%" mx="auto" position="relative">
      <NextLink href={data.WebAddress.Url} passHref>
        <Anchor noOfLines={1}>
          <Image
            src={data.ImageUrl}
            alt={`Image of ${data.StoreName}`}
            aspectRatio="16:9"
            size="large"
            sizes="(max-width: 1280px) 100vw, 700px"
            objectFit="cover"
            priority={data.priority}
          />
        </Anchor>
      </NextLink>

      <Box
        bottom={6}
        left={0}
        right={0}
        zIndex={1}
        bgColor="brand.gray.300"
        p={3}
      >
        <Flex
          width="100%"
          justifyContent="space-between"
          alignItems="baseline"
          flexFlow={{ base: "column", sm: "row" }}
        >
          <NextLink href={data.WebAddress.Url} passHref>
            <Anchor noOfLines={1}>
              <Heading
                as="h2"
                noOfLines={1}
                size="sm"
                textTransform="uppercase"
                fontWeight={400}
              >
                {data.StoreName}
              </Heading>
            </Anchor>
          </NextLink>
          {data.WebAddress && (
            <NextLink href={data.WebAddress.Url} passHref>
              <Anchor noOfLines={1}>
                <Text fontWeight={400}>{data.WebAddress.Title}</Text>
              </Anchor>
            </NextLink>
          )}
        </Flex>
        <Flex
          width="100%"
          justifyContent="space-between"
          alignItems="baseline"
          flexFlow={{ base: "column", sm: "row" }}
          mb={2}
        >
          <Anchor type="Email" href={`mailto:${data.Email}`}>
            <Text variant="tight">{data.Email}</Text>
          </Anchor>
          <Anchor type="Phone" href={`tel:${data.Phone}`}>
            <Text variant="tight">{data.Phone}</Text>
          </Anchor>
        </Flex>
        <Flex justifyContent="space-between" hidden={onMobile}>
          <Stack spacing={0} maxWidth="70%">
            <Text textTransform="uppercase" fontWeight={400}>
              {dict("FlagShipStoreAddressTitle")}
            </Text>
            <Text variant="tight" noOfLines={2}>
              {data.Address}
            </Text>
            <Text variant="tight" noOfLines={1}>
              {data.Zip} {data.City}
            </Text>
            <Text variant="tight" noOfLines={1}>
              {data.Country}
            </Text>
          </Stack>
          <Stack spacing={0}>
            {data.WebAddress ? (
              <NextLink href={data.WebAddress.Url} passHref>
                <Anchor
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Text
                    textTransform="uppercase"
                    noOfLines={1}
                    fontWeight={400}
                    textAlign="right"
                  >
                    {dict("FlagShipStoreTitle")}
                  </Text>
                  <ArrowForwardIcon ml={1} display="inline-block" />
                </Anchor>
              </NextLink>
            ) : (
              <Text
                textTransform="uppercase"
                noOfLines={1}
                fontWeight={400}
                textAlign="right"
              >
                {dict("FlagShipStoreTitle")}
              </Text>
            )}

            {Array(4)
              .fill("")
              .map((_, index) => {
                return (
                  <Text
                    variant="tight"
                    noOfLines={1}
                    textAlign="right"
                    key={index}
                    height="1.3rem"
                  >
                    {hours[index]}
                  </Text>
                );
              })}
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
}

export default FlagshipStoresCard;
