import { AspectRatio, useBreakpointValue, useToken } from "@chakra-ui/react";
import useWindowSize from "@hooks/useWindowSize";
import { ReactElement, useEffect, useState } from "react";
import type { BaseReactPlayerProps } from "react-player/base";
import ReactPlayer from "react-player/lazy";

import { VideoComponentType } from "./Video.model";

type VideoTypes = VideoComponentType & BaseReactPlayerProps;

function VideoComponent({
  mobileUrl,
  url,
  aspectRatio,
  ...rest
}: VideoTypes): ReactElement {
  const { width } = useWindowSize();
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    if (width) {
      setVideoUrl(width < 480 ? mobileUrl || url : url);
    }
  }, [width]);

  const isMobile = useBreakpointValue({ base: true, sm: false });
  const showMobile = !!mobileUrl && isMobile;
  if (!videoUrl) return null;
  return (
    <AspectRatio ratio={aspectRatio || showMobile ? 1 / 1 : 16 / 9}>
      <ReactPlayer width="auto" height="auto" url={videoUrl} {...rest} />
    </AspectRatio>
  );
}
export default VideoComponent;
