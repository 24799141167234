import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  UseDisclosureProps,
} from "@chakra-ui/react";
import { useTranslations } from "next-intl";
import NextLink from "next/link";
import React from "react";

interface ChsAlertProps extends UseDisclosureProps {
  heading?: string;
  buttonText?: string;
  buttonLink?: string;
  children?: React.ReactNode;
}

export function ChsAlert({
  heading,
  buttonText,
  buttonLink,
  isOpen,
  onClose,
  children,
}: ChsAlertProps) {
  const cancelRef = React.useRef();
  const sharedDict = useTranslations("Shared");
  const errorHeading = heading || sharedDict("Validation.ErrorDialogHeading");
  const buttonLabel = buttonText || sharedDict("Validation.ErrorDialogButton");
  const errorMessage = children || sharedDict("Validation.ErrorInvalidOrder");

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {errorHeading}
            </AlertDialogHeader>
            <AlertDialogCloseButton ref={cancelRef} onClick={onClose} />
            <AlertDialogBody>{errorMessage}</AlertDialogBody>
            <AlertDialogFooter>
              {buttonLink ? (
                <NextLink href={buttonLink} passHref>
                  <Button colorScheme="red" onClick={onClose} ml={3}>
                    {buttonLabel}
                  </Button>
                </NextLink>
              ) : (
                <Button colorScheme="red" onClick={onClose} ml={3}>
                  {buttonLabel}
                </Button>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
