import {
  Link as Anchor,
  Box,
  Flex,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";
import FavoritesButton from "@components/FavoritesButton.component";
import { ImageAspectRatios, ImageSizes } from "@components/Image/Image.model";
import NextLink from "next/link";

import Image from "../../Image";
import type { CaseListItemType } from "./CaseListBlock.model";

export function GetInfo(landscape: boolean): {
  imageOrientation: string;
  colSpan: number[];
  size: keyof typeof ImageSizes;
  aspectRatio: keyof typeof ImageAspectRatios;
} {
  return {
    imageOrientation: landscape ? "LandscapeImage" : "PortraitImage",
    colSpan: landscape ? [4, 4, 2, 2] : [4, 4, 2, 1],
    size: landscape ? "medium" : "small",
    aspectRatio: landscape ? "16:9" : "5:6",
  };
}

interface CaseListProps {
  data: CaseListItemType;
  landscape: boolean;
}
export function CaseListItem({
  data,
  landscape,
}: CaseListProps): React.ReactElement {
  const { imageOrientation, colSpan, size, aspectRatio } = GetInfo(landscape);
  const { AddToFavourites, CategoryLink, PageLink, TeaserText } = data;

  const sizes = `(max-width: 768px) 100vw,
                 (max-width: 992px) 50vw,
                 ${landscape ? "660px" : "330px"}`;

  return (
    <GridItem paddingY={2} colSpan={colSpan} w="100%" h="100%">
      <Box overflow={"hidden"}>
        <Box
          sx={{
            transitionProperty: "transform",
            transitionDuration: "imageHover",
            transitionTimingFunction: "imageHover",
          }}
          _hover={{
            transform: "scale(1.1)",
            cursor: "pointer",
          }}
        >
          <NextLink href={data.PageLink?.Url} passHref>
            <Anchor>
              <Image
                src={data[imageOrientation]?.Url}
                alt={data[imageOrientation]?.AlternativeText}
                aspectRatio={aspectRatio}
                sizes={sizes}
                size={size}
                objectFit="cover" // @todo: API needs to provide correct aspect ratio crops.
              />
            </Anchor>
          </NextLink>
        </Box>
      </Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Text
            fontSize="xs"
            color="gray.400"
            lineHeight="1.5rem"
            minHeight="1.5rem"
            verticalAlign="center"
          >
            {CategoryLink.Title}
          </Text>
          <NextLink href={PageLink.Url} passHref>
            <Anchor>
              <Heading
                as="h2"
                size="xs"
                _hover={{
                  cursor: "pointer",
                }}
              >
                {PageLink.Title}
              </Heading>
            </Anchor>
          </NextLink>
        </Box>
        {AddToFavourites && (
          <FavoritesButton showText={false} id={data.CaseId} type="case" />
        )}
      </Flex>
      <Text paddingTop={2} fontSize="s">
        {TeaserText}
      </Text>
    </GridItem>
  );
}
