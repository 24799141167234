import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Link as Anchor,
  Box,
  Button,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";
import RichTextComponent from "@components/RichText.component";
import NextLink from "next/link";
import { ReactElement } from "react";

import type { HeroBlockType } from "./HeroBlock.model";

function HeroBlockCopyComponent({
  Heading: heading,
  SubHeading,
  TeaserText,
  Link: link,
  Images,
  ContentWidth,
}: HeroBlockType): ReactElement {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { IsImageDark } = Images[0];
  const contentWidth =
    ContentWidth > 0 ? "calc(" + ContentWidth + "/12 * 100%)" : "70%";
  return (
    <>
      {Heading && (
        <Heading
          as="h1"
          mb={8}
          variant="heading1"
          maxWidth={isMobile ? "100%" : contentWidth}
          sx={{ "overflowWrap": "normal" }}
        >
          {heading}
        </Heading>
      )}
      {SubHeading && (
        <Heading as="h2" mb={2} variant="heading3">
          {SubHeading}
        </Heading>
      )}
      {TeaserText && (
        <Box fontSize="sm" marginY={5}>
          <RichTextComponent text={TeaserText} />
        </Box>
      )}
      {link && (
        <NextLink href={link.Url} passHref>
          <Anchor
            display="flex"
            alignItems="center"
            sx={{ textDecoration: "none !important" }}
          >
            <Button
              variant={
                isMobile ? "heroDark" : IsImageDark ? "heroLight" : "heroDark"
              }
              mt={4}
              mb={10}
              mr={2}
            >
              {link.Title || link.Url}
            </Button>
            {/* <ArrowForwardIcon /> */}
          </Anchor>
        </NextLink>
      )}
    </>
  );
}

export default HeroBlockCopyComponent;
