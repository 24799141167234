import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ReactElement } from "react";

import type { CaseListBlockType } from "./CaseListBlock.model";
import { CaseListItem } from "./CaseListItem";
import { StudioCaseListItem } from "./CaseListItem.studio";

function CaseListBlock(data: CaseListBlockType): ReactElement {
  const forceLandscape = useBreakpointValue({ base: true, lg: false });
  function isLandscape(key: number): boolean {
    return forceLandscape || key % 6 < 2;
  }

  return (
    <Box>
      <Container position="relative">
        <Heading variant="heading2" as="h2" pb={4}>
          {data.Heading}
        </Heading>
        <SimpleGrid
          columns={data.variant === "Studio" ? 6 : 4}
          gap={[2, null, 3, 4]}
        >
          {data.Cases?.map((caseData, key) =>
            data.variant === "Studio" ? (
              <StudioCaseListItem
                data={caseData}
                landscape={true}
                aspectRatioOverride="1:1"
                colSpanOverride={{ base: 6, sm: 3, md: 2 }}
                key={key}
              />
            ) : (
              <CaseListItem
                data={caseData}
                landscape={isLandscape(key)}
                key={key}
              />
            )
          )}
        </SimpleGrid>
      </Container>
    </Box>
  );
}

export default CaseListBlock;
