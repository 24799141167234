import CheckoutBasketPageModel from "@components/Pages/BasketPage.component";
import CheckoutAddressPageModel from "@components/Pages/CheckoutAddressPage.component";
import CheckoutReceiptPageModel from "@components/Pages/CheckoutReceiptPage.component";
import DesignerPageModel from "@components/Pages/DesignerPage.component";
import FavoritPageModel from "@components/Pages/FavoritPage.component";
import FilterPageModel from "@components/Pages/FilterPage.component";
import ProductPageModel from "@components/Pages/ProductDisplay.component";
import StudioProductPageModel from "@components/Pages/StudioProductPage.component";
import StudioStandardPageModel from "@components/Pages/StudioStandardPage.component";
import StudioStartPageModel from "@components/Pages/StudioStartPage.component";
import React from "react";

import type { PageBuilderType } from "./PageBuilder.model";

const PageVariants = {
  FilterPageModel,
  StudioStartPageModel,
  StudioStandardPageModel,
  ProductPageModel,
  DesignerPageModel,
  StudioProductPageModel,
  CheckoutBasketPageModel,
  CheckoutAddressPageModel,
  CheckoutReceiptPageModel,
  FavoritPageModel,
};

function PageResolverComponent({
  pageData,
}: PageBuilderType): React.ReactElement {
  const { MetaDataTitle, MetaDataDescription, Content, ...data } = pageData;

  const Component = PageVariants[data.Kind];

  if (!Component) return;

  return React.createElement(Component, { ...data });
}

export default PageResolverComponent;
