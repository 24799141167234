import { Box, Flex, Text } from "@chakra-ui/react";
import useBasket from "@hooks/useBasket";
import { PaymentSupplier } from "@modules/Basket/Basket.model";
import NextImage from "next/future/image";
import { useEffect, useState } from "react";

export const PaymentMethods = () => {
  const [paymentOptions, setPaymentOptions] = useState<
    PaymentSupplier["PaymentOptions"]
  >([]);

  const PaymentOption = ({ Name, ImageUrl }) => (
    <Box
      height={{ base: "30px", md: "45px" }}
      width={{ base: "100px", md: "150px" }}
      position="relative"
      objectFit="contain"
    >
      <NextImage
        src={ImageUrl}
        alt={`Payment provider ${Name}`}
        sizes="10vw"
        fill
        style={{
          objectFit: "contain",
        }}
      />
    </Box>
  );
  const { getPaymentSuppliers } = useBasket();
  useEffect(() => {
    const fetchPaymentSuppliers = async () => {
      const { Supplier, PaymentOptions }: PaymentSupplier =
        await getPaymentSuppliers();
      setPaymentOptions(PaymentOptions);
    };
    fetchPaymentSuppliers().catch(console.error);
  }, []);
  return (
    <Flex
      maxWidth="200px"
      minWidth="100%"
      flexDirection="row"
      alignItems="center"
    >
      {paymentOptions?.map((option) => (
        <PaymentOption {...option} key={option.Name} />
      ))}
    </Flex>
  );
};
