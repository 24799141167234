import {
  Box,
  Container,
  Divider,
  Flex,
  GridItem,
  Heading,
  SimpleGrid,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  VStack,
} from "@chakra-ui/react";
import RichTextComponent from "@components/RichText.component";
import getPriceFormat from "@hooks/getPriceFormat";
import { getWeek } from "@hooks/getTime";
import useBasket from "@hooks/useBasket";
import ReceiptBasketLine from "@modules/Basket/ReceiptBasketLine/ReceiptBasketLine.component";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import type {
  AddressModel,
  CheckoutReceiptAddressViewModel,
  CheckoutReceiptOrderViewModel,
  CheckoutReceiptViewModel,
} from "./CheckoutReceiptPage.model";

type AddressBlockModel = {
  heading: string;
} & (CheckoutReceiptAddressViewModel | AddressModel);

const AddressBlock = ({
  heading,
  Name,
  Street,
  ZipCode,
  City,
  Phone,
  Email,
}: AddressBlockModel) => {
  return (
    <VStack align="left" spacing={0} py={4}>
      <Text fontWeight="semibold">{heading}</Text>
      <Text>{Name}</Text>
      <Text>{Street}</Text>
      <Text>
        {ZipCode}
        {City}
      </Text>
      <Text>{Phone}</Text>
      <Text>{Email}</Text>
    </VStack>
  );
};

type OrderOverviewBlockModel = {
  CurrencySymbol: string;
  Order: CheckoutReceiptOrderViewModel;
};

const OrderOverviewBlock = ({
  Order,
  CurrencySymbol,
}: OrderOverviewBlockModel) => {
  const dictShared = useTranslations("CheckoutShared.OrderSummary");
  return (
    <VStack align="left" pb={8}>
      {Order?.Lines.map((line) => (
        <ReceiptBasketLine
          {...line}
          key={line.VariantId}
          CurrencySymbol={CurrencySymbol}
        />
      ))}
      <Stat>
        <Flex justifyContent="space-between" padding={1} alignItems="center">
          <StatLabel>{dictShared("TextSummaryShipping")}</StatLabel>
          <StatNumber fontSize="md">
            {getPriceFormat(Order?.ShippingPrice, CurrencySymbol)}
          </StatNumber>
        </Flex>
      </Stat>
      <Divider />
      {Order?.DiscountValue > 0 && (
        <>
          <Stat color="brand.red">
            <Flex
              justifyContent="space-between"
              padding={1}
              alignItems="center"
            >
              <StatLabel>{dictShared("TextSummaryDiscounts")}</StatLabel>
              <StatNumber fontSize="md">
                {getPriceFormat(Order?.Discount, CurrencySymbol)}
              </StatNumber>
            </Flex>
          </Stat>
          <Divider />
        </>
      )}

      <Stat py={4}>
        <Flex justifyContent="space-between" padding={1}>
          <StatLabel>
            {dictShared("TextSummaryTotal")} ({dictShared("TextIncludingVat")})
          </StatLabel>
          <StatNumber fontSize="md">
            {getPriceFormat(Order?.TotalPrice, CurrencySymbol)}
          </StatNumber>
        </Flex>
      </Stat>
      <Divider />
    </VStack>
  );
};

function CheckoutReceiptPage() {
  const dict = useTranslations("CheckoutReceiptPage");
  const dictShared = useTranslations("CheckoutShared.OrderSummary");
  const dictService = useTranslations("CheckoutShared.CustomerService");
  const { fetchReceiptData } = useBasket();
  const { query, isReady } = useRouter();
  const orderId = query.orderid || query.orderId;
  const [week, setWeek] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [receiptData, setReceiptData] =
    useState<CheckoutReceiptViewModel>(null);

  useEffect(() => {
    const fetchIt = async () => {
      setLoading(true);
      const response = await fetchReceiptData({
        orderId: orderId.toString(),
      });
      setReceiptData(response);
      setLoading(false);
    };
    if (isReady && orderId) {
      fetchIt().catch(console.error);
    }
  }, [orderId]);

  useEffect(() => {
    let res = 0;
    if (receiptData) {
      res = getWeek(receiptData.Delivery?.DeliveryDate);
    }
    setWeek(res);
  }, [receiptData]);

  if (loading) {
    return (
      <Container pt={0}>
        <Spinner />
      </Container>
    );
  }
  return receiptData?.OrderId ? (
    <Container pt={0}>
      <Box float="right">
        <Text textAlign="right">{dict("TextOrderConfirmation")}</Text>
        <Text textAlign="right" fontWeight="semibold">
          {dict("TextOrderNo")}: {receiptData.OrderId}
        </Text>
      </Box>
      <SimpleGrid columns={{ base: 1, lg: 2 }} gap={4} width="100%" pt={8}>
        <GridItem mr={20}>
          <Heading variant="heading2">{dict("HeadingOrderPlaced")}</Heading>
          <Divider my={8} />
          <RichTextComponent text={receiptData?.OrderDescription} />
        </GridItem>
        <GridItem>
          <Heading variant="heading2">{dictShared("HeadingSummary")}</Heading>
          <Divider my={8} />
          <OrderOverviewBlock
            Order={receiptData.Order}
            CurrencySymbol={receiptData.CurrencySymbol}
          />
          <Heading variant="heading2">
            {dictShared("TextSummaryShipping")}
          </Heading>
          <Divider mt={8} />
          <Text py={4}>
            {dict("TextExpectedDelivery", {
              0: week,
            })}
          </Text>
          <AddressBlock
            {...receiptData.Delivery?.DeliveryAddress}
            heading={dict("HeadingDeliveryAddress")}
          />
          <AddressBlock
            {...receiptData.InvoiceAddress}
            heading={dict("HeadingInvoiceAddress")}
          />

          <Heading py={4} variant="heading2">
            {dict("HeadingCustomerService")}
          </Heading>
          <Divider my={4} />
          <Text fontWeight="semibold">
            {dictService("TextIsOpenDescription")}
          </Text>
          <Text fontSize="3xl">{dictService("HeadingPhoneNumber")}</Text>
          <Text>{dictService("TextOpeningHours")}</Text>
        </GridItem>
      </SimpleGrid>
    </Container>
  ) : (
    <Container>
      <Heading>{dict("HeadingNoOrderId")}</Heading>
      <Text>{dict("TextNoOrderId")}</Text>
    </Container>
  );
}

export default CheckoutReceiptPage;
