import { Box, Container, Flex, Heading } from "@chakra-ui/react";
import Image from "@components/Image";
import RichTextComponent from "@components/RichText.component";
import { ReactElement } from "react";

import type { ProductInspirationBlockType } from "./ProductInspirationBlock.model";
import ProductInspirationItem from "./ProductInspirationItem";

export function ProductInspirationBlock(
  data: ProductInspirationBlockType
): ReactElement {
  if (!data.ProductInspirationItems?.length) return;
  const leftProduct = data.ProductInspirationItems[0]?.InspirationProduct;
  const rightProduct = data.ProductInspirationItems[1]?.InspirationProduct;
  const leftImage = data.ProductInspirationItems[0]?.InspirationImage;
  const rightImage = data.ProductInspirationItems[1]?.InspirationImage;
  // const leftCase = data.ProductInspirationItems[0].InspirationCaseLink;
  // const rightCase = data.ProductInspirationItems[1]?.InspirationCaseLink;

  // TODO: Maybe these images are what im looking for ?

  return (
    <Container className={data.Kind}>
      <Box mr={{ base: 0, md: "50%" }}>
        {data.Heading && (
          <Heading as="h2" size="md">
            {data.Heading}
          </Heading>
        )}
        {data.Teaser && (
          <Box marginY={8}>
            <RichTextComponent text={data.Teaser} />
          </Box>
        )}
      </Box>
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        gap={4}
        px={{ base: 0, md: 20 }}
      >
        {leftImage && (
          <Box w={{ base: "100%", md: "60%" }}>
            <Image
              src={leftImage?.Url}
              alt={leftImage?.AlternativeText}
              aspectRatio="4:3"
              crop
              sizes="(max-width: 768px) 100vw,
                     (max-width: 1280px) 60vw,
                     700px"
            />
            {leftProduct && leftProduct.Images && (
              <ProductInspirationItem {...leftProduct} />
            )}
          </Box>
        )}
        {rightImage && (
          <Flex w={{ base: "100%", md: "40%" }} alignItems="end">
            <Box w="100%">
              <Image
                src={rightImage.Url}
                alt={rightImage.AlternativeText}
                aspectRatio="3:4"
                crop
                sizes="(max-width: 768px) 100vw,
                       (max-width: 1280px) 40vw,
                       500px"
              />
            </Box>
          </Flex>
        )}
      </Flex>
      {rightProduct && rightProduct.Images && (
        <Box px={{ base: 0, md: 20 }}>
          <ProductInspirationItem {...rightProduct} />
        </Box>
      )}
    </Container>
  );
}

export default ProductInspirationBlock;
