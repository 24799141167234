import {
  Box,
  Button,
  Center,
  Container,
  Grid,
  GridItem,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
  useBreakpointValue
} from "@chakra-ui/react";
import { CheckoutNav } from "@components/Checkout/CheckoutNav.component";
import { CheckoutSummary } from "@components/Checkout/CheckoutSummary/CheckoutSummary.component";
import RichTextComponent from "@components/RichText.component";
import { useScrollDirection } from "@hooks/useScrollDirection";
import { ReactElement, useState, useEffect } from "react";
import MotionBox from "@components/MotionBox.component";


interface FaqElement {
  LinkTitle?: string;
  Title?: string;
  Description?: string;
}

interface CheckoutLayoutModel {
  basket?: boolean;
  FaqHeader?: string;
  FaqList?: FaqElement[];
  children?: React.ReactNode;
}

function CheckoutLayout({
  basket = false,
  FaqHeader: faqHeader,
  FaqList: faqList,
  children,
}: CheckoutLayoutModel): ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalText, setModalText] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [minimizeNav, setMinimizeNav] = useState(false);
  const scrollDirection = useScrollDirection();
  const isDesktopNav = useBreakpointValue({ base: false, lg: true });
  const [navHeight, setNavHeight] = useState(130);
  useEffect(() => {
    if (scrollDirection === "down") {
      setMinimizeNav(true);
      setNavHeight(isDesktopNav ? 60 : 40);
    }
    if (scrollDirection === "up") {
      setMinimizeNav(false);
      setNavHeight(isDesktopNav ? 130 : 40);
    }
  }, [scrollDirection]);

  const handleFaqModalClick = (faqElement: FaqElement) => {
    setModalTitle(faqElement.Title);
    setModalText(faqElement.Description);
    onOpen();
  };
  return (
    <Container py={0} pb={75}>
      <Grid gridTemplateColumns={{ base: "1fr", lg: "1fr 500px" }} gap={10}>
        <GridItem as="main">
          {!basket ? <CheckoutNav /> : <></>}
          <>{children}</>
        </GridItem>
        <GridItem as="aside">
          <MotionBox display="flex"
            initial={{ top: { base: 40, lg: 130 } }}
            animate={
              isDesktopNav
                ? {
                  top: minimizeNav ? [130, 60] : [60, 130],
                }
                : { top: 40 }
            }
            transition={{ duration: 0.25 }}
            position={{ base: "relative", md: "sticky" }}
            flexFlow="column nowrap"
            flexGrow="1"
            flexBasis={{ base: "unset", md: "436px" }}
            alignSelf="start"
            overflowY="auto"
            pt={{ base: 4, md: 2 }}
            sx={{
              "scrollbar-width": "none",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}>
            <CheckoutSummary basket={basket} />
            {basket ? (
              <></>
            ) : (
              <SimpleGrid columns={1} spacing={8} paddingTop={10}>
                <Center>
                  <GridItem colSpan={{ base: 2, lg: 3 }}>
                    <Heading
                      variant="heading3"
                      mb={4}
                      fontWeight="bold"
                      paddingBottom="2"
                    >
                      {faqHeader}
                    </Heading>
                    {faqList.map((value, index) => (
                      <Box
                        paddingTop="2"
                        paddingBottom="2"
                        fontSize="lg"
                        fontWeight="bold"
                        key={index}
                      >
                        <Link onClick={() => handleFaqModalClick(value)}>
                          <Button
                            variant="link"
                            size="xs"
                            mx={2}
                            textDecoration="underline"
                            margin="0"
                          >
                            {value.LinkTitle}
                          </Button>
                        </Link>
                      </Box>
                    ))}
                    <Modal onClose={onClose} size="3xl" isOpen={isOpen}>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>{modalTitle}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          <RichTextComponent text={modalText} />
                        </ModalBody>
                        <ModalFooter></ModalFooter>
                      </ModalContent>
                    </Modal>
                  </GridItem>
                </Center>
              </SimpleGrid>
            )}
          </MotionBox>
        </GridItem>
      </Grid>
    </Container >
  );
}

export default CheckoutLayout;
