import { GridItem, useBreakpointValue } from "@chakra-ui/react";
import { GenericCarousel } from "@components/GenericCarousel";
import { ProductType } from "@components/Pages/ProductDisplay.model";
import {
  ProductListType,
  ProductTeaserType,
} from "@components/ProductList/ProductList.model";
import ProductTeaser from "@components/ProductList/ProductTeaser.component";

function ProductCarouselComponent({ products }: ProductListType) {
  if (!products) {
    return null;
  }

  // Option 1: Determine the most common image size for caroussel
  // const wideProducts = products.filter(
  //   (product) => product.ImageSize === "wide"
  // ).length;
  // const notWideProducts = products.length - wideProducts;
  // const mostCommonImageSize =
  //   wideProducts > notWideProducts ? "wide" : "notWide";

  // Option 2: Look at the first product and use its image size
  // const firstProduct = products[0];
  // const mostCommonImageSize = firstProduct?.ImageSize || "notWide";

  // Used for Option 1 and 2
  // const perPage = useBreakpointValue(
  //   mostCommonImageSize === "wide"
  //     ? { base: 1, sm: 1, md: 2, lg: 2 }
  //     : { base: 1, sm: 2, md: 3, lg: 3 }
  // );

  const perPage = useBreakpointValue({ base: 2, sm: 2, md: 3, lg: 3 });
  const height = useBreakpointValue({
    base: "246px",
    sm: "420px",
    md: "360px",
    lg: "505px",
  });

  return products ? (
    <GenericCarousel perPage={perPage} controls>
      {products.map((product: ProductTeaserType | ProductType) => (
        <GridItem
          key={product.Sku}
          colSpan={
            product.ImageSize === "wide"
              ? { base: 12, md: 6 }
              : { base: 6, md: 4 }
          }
        >
          <ProductTeaser
            {...product}
            sizes={product.ImageSize === "wide" ? "600px" : "300px"}
            height={height}
          />
        </GridItem>
      ))}
    </GenericCarousel>
  ) : (
    <></>
  );
}

export default ProductCarouselComponent;
