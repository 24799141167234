import { Box, Container, Heading, Text } from "@chakra-ui/react";
import HeroBlock from "@components/Blocks/HeroBlock";
import getColor, { getFGColor } from "@hooks/getColor";

import { StudioStandardPageType } from "./StudioStandardPage.model";

function StudioStandardPageComponent({
  Heading: heading,
  Description,
  Hero,
  Kind,
  slug,
}: StudioStandardPageType) {
  const bgColor = getColor(Kind, slug);
  const fgColor = getFGColor(bgColor);
  const isContactPage = slug.includes("contact");
  const noHero = !Hero.Images[0]?.ImageDesktop;
  return (
    <Box bgColor={bgColor} color={fgColor}>
      <Container
        paddingY={{ base: 0, md: isContactPage ? 0 : noHero ? 0 : 20 }}
        px={{ base: 0, md: 4 }}
      >
        {heading ? (
          <Heading
            as="h1"
            size="lg"
            width={{ base: "100%", md: "50%" }}
            paddingX={{ base: 4, md: 0 }}
            paddingY={8}
          >
            {heading}
          </Heading>
        ) : (
          <></>
        )}
        {Description ? (
          <Text
            paddingY={{ base: 2, md: 8 }}
            width={{ base: "100%", md: "50%" }}
            paddingX={{ base: 4, md: 0 }}
          >
            {Description}
          </Text>
        ) : (
          <></>
        )}
        {noHero ? <></> : <HeroBlock {...Hero} variant="Studio" priority />}
      </Container>
    </Box>
  );
}

export default StudioStandardPageComponent;
