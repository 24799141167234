import { Flex, Heading } from "@chakra-ui/react";

interface CheckoutStepProps {
  Step?: string;
  Heading?: string;
}

export const CheckoutStep = (props: CheckoutStepProps) => {
  return (
    <Flex alignItems="center">
      <Flex
        borderRadius="50%"
        bg="blackAlpha.900"
        color="white"
        px={4}
        h={10}
        w={10}
        justifyContent="center"
        alignItems="center"
        fontSize="20"
      >
        {props.Step}
      </Flex>
      <Heading as="h3" size="lg" ml={4}>
        {props.Heading}
      </Heading>
    </Flex>
  );
};
