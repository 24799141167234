import {
  Link as Anchor,
  Box,
  Button,
  Container,
  GridItem,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import Image from "@components/Image/Image.component";
import RichTextComponent from "@components/RichText.component";
import NextLink from "next/link";
import { ReactElement, useEffect, useState } from "react";

import type { BodyMediaColorBlockType } from "./BodyMediaColorBlock.model";

export function BodyMediaColorBlock(
  data: BodyMediaColorBlockType
): ReactElement {
  let imgColSpan = 4;
  if (data.ImageWidth === "1/3") imgColSpan = 2;
  else if (data.ImageWidth === "1/2") imgColSpan = 3;
  else imgColSpan = 4;

  return (
    <Container paddingTop={10} className={data.Kind}>
      <SimpleGrid columns={6} gap={5}>
        <GridItem
          colSpan={{ base: 6, lg: 6 - imgColSpan }}
          order={data.IsTextRightAligned ? 1 : "unset"}
        >
          <Box
            display="flex"
            justifyContent="center"
            height="100%"
            flexFlow="column nowrap"
          >
            <Heading as="h2" size="lg">
              {data.Heading}
            </Heading>
            <Heading as="h3" fontSize="md">
              {data.SubHeading}
            </Heading>
            <Box fontSize="md" fontWeight={300} my="4">
              <RichTextComponent text={data.Body} />
            </Box>
            {data.Link && (
              <NextLink href={data.Link.Url} passHref>
                <Anchor
                  display="flex"
                  alignItems="center"
                  sx={{ textDecoration: "none !important" }}
                >
                  <Button variant="heroDark" mt={4} mb={10} mr={2}>
                    {data.Link.Title || data.Link.Url}
                  </Button>
                  {/* <ArrowForwardIcon /> */}
                </Anchor>
              </NextLink>
            )}
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 6, lg: imgColSpan }}
          position="relative"
          my="auto"
        >
          <Image
            src={data.Image?.Url}
            alt={data.Image?.AlternativeText}
            aspectRatio="16:9"
            size={imgColSpan < 4 ? "medium" : "large"}
            sizes="(max-width: 992px) 100vw,
                   900px"
          />
        </GridItem>
      </SimpleGrid>
    </Container>
  );
}

export default BodyMediaColorBlock;
