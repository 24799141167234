import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import ImageComponent from "@components/Image";
import getConfig from "@hooks/getConfig";
import StoreLocator from "@modules/StoreLocator";
import StoreLocatorSearchComponent from "@modules/StoreLocator/StoreLocatorSearch.component";
import { useTranslations } from "next-intl";
import { useCallback, useEffect, useRef, useState } from "react";

import FlagshipStoresBlock from "../FlagshipStoresBlock";
import { StoreLocatorBlockType } from "./StoreLocatorBlock.model";

function StoreLocatorBlock(data: StoreLocatorBlockType) {
  const flagshipStores = data.Stores.filter((store) => {
    return store.IsFlagship;
  });
  const [searchView, setSearchView] = useState(false);
  const [viewportPosition, setViewportPosition] = useState({
    latitude: 55.6818445,
    longitude: 12.5858641,
  });

  const translations = useTranslations("StoreLocator");

  const [active, setActive] = useState(false);

  const inputRef = useRef();

  const handleSearch = (response) => {
    const [longitude, latitude] = response.coordinates;
    if (!active) {
      setActive(true);
      setTimeout(() => {
        setViewportPosition({ longitude, latitude });
      }, 1000);
    } else {
      setViewportPosition({ longitude, latitude });
    }
  };

  const handleLocation = useCallback(() => {
    typeof window !== "undefined" &&
      window.navigator.geolocation.getCurrentPosition(
        ({ coords: { longitude, latitude } }) => {
          handleSearch({
            name: "Location",
            coordinates: [longitude, latitude],
          });
        }
      );
  }, []); // eslint-disable-line

  return (
    <>
      <Container py={0}>
        <SimpleGrid columns={2} fontSize="2xl" pt={4} pb={20}>
          <Flex justifyContent="center" alignItems="center">
            <Button
              variant="link"
              fontWeight={!searchView ? 600 : 300}
              textDecoration={!searchView ? "underline" : "unset"}
              textUnderlineOffset={20}
              onClick={() => setSearchView(false)}
            >
              FLAGSHIP STORES
            </Button>
          </Flex>
          <Flex justifyContent="center" alignItems="center">
            <Button
              variant="link"
              fontWeight={searchView ? 600 : 300}
              textDecoration={searchView ? "underline" : "unset"}
              textUnderlineOffset={20}
              onClick={() => setSearchView(true)}
            >
              {translations("Header")}
            </Button>
          </Flex>
        </SimpleGrid>
      </Container>
      {searchView ? (
        <Box
          backgroundColor={data.BackgroundColor}
          position="relative"
          paddingY={10}
          className={data.Kind}
        >
          <Container>
            <Box display="flex" justifyContent="center" textAlign="center">
              <Box padding={5} color="white">
                <Heading variant="title">{translations("Header")}</Heading>
                <Text fontSize="sm" marginY={5}>
                  {translations("SubHeader")}
                </Text>
                <Box marginY={5}>
                  <StoreLocatorSearchComponent callback={handleSearch} />
                </Box>
                <Button size="sm" onClick={handleLocation}>
                  {translations("ButtonText")}
                </Button>
              </Box>
            </Box>
          </Container>
          <Box height={active ? { base: "auto", lg: "50vh" } : "auto"}>
            {active ? (
              <StoreLocator
                data={data}
                viewportPosition={viewportPosition}
                geocoderContainerRef={inputRef}
              />
            ) : (
              <ImageComponent
                src={data.Image?.Url}
                aspectRatio="16:9"
                alt={data.Image?.AlternativeText}
                sizes="(max-width: 1280px) 100vw,
                       1920px"
              />
            )}
          </Box>
        </Box>
      ) : (
        <FlagshipStoresBlock
          stores={flagshipStores}
          Kind="FlagshipsstoresBlock"
        />
      )}
    </>
  );
}

export default StoreLocatorBlock;
