import { Box } from "@chakra-ui/react";
import Blocks from "@components/Blocks";
import { PageBuilderProvider } from "@modules/PageBuilder/PageBuilder.context";
import Head from "next/head";
import { ReactElement } from "react";
import { PageBuilderType } from "./PageBuilder.model";
import PageResolver from "./PageResolver";
import { ProductDisplayType } from "@components/Pages/ProductDisplay.model";
import getConfig from "next/config";

function PageBuilderComponent({
  pageData,
  marketData,
  staticData,
}: PageBuilderType): ReactElement {
  if (pageData) {
    let productPageMarkup;
    let pricesExists = false;
    if (pageData.Kind === "ProductPageModel") {
      const productPage = (pageData as ProductDisplayType);
      const focusedVariant = productPage.Variants.filter(v => v.IsFocused)[0];
      const cloudinaryUrls = focusedVariant.Images.map(image => ConvertEpiImageToCloudinary(image.Url, image.Width));
      const productIsDiscounted = focusedVariant.FormattedPrice < focusedVariant.FormattedListedPrice;
      if (focusedVariant.ListedPrice || focusedVariant.Price) {
        pricesExists = true;
      }

      productPageMarkup = {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: productPage.ProductName,
        image: cloudinaryUrls,
        description: productPage.Description,
        brand: {
          "@type": "Brand",
          name: productPage.Designer.Name
        },
        sku: focusedVariant.Sku,
        offers: {
          "@type": "Offer",
          url: focusedVariant.PageUrl,
          priceCurrency: marketData.CurrencyCode,
          price: productIsDiscounted ? focusedVariant.Price : focusedVariant.ListedPrice,
          priceSpecification: productIsDiscounted ? {
            "@type": "UnitPriceSpecification",
            priceType: "https://schema.org/ListPrice",
            price: focusedVariant.ListedPrice,
            priceCurrency: marketData.CurrencyCode
          } : {},
          availability: "https://schema.org/InStock",
          itemCondition: "https://schema.org/NewCondition"
        }
      };
    }
    return (
      <>
        <Head>
          <title>{pageData.MetaDataTitle}</title>
          <meta name="description" content={pageData.MetaDataDescription} />
          {pageData.HrefLangUrls.map((x) => (
            <link
              rel="alternate"
              key={x.LanguageCode}
              hrefLang={x.LanguageCode}
              href={x.Url}
            />
          ))}
          <link rel="canonical" href={pageData.CanonicalUrl} />
          {pageData.SiteNameMarkup
            ? <script type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: pageData.SiteNameMarkup }}
            >
            </script>
            : null
          }
          {pageData.SiteNavigationMarkup
            ? <script type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: pageData.SiteNavigationMarkup }}
            >
            </script>
            : null
          }
          {pageData.Kind === "ProductPageModel" && pricesExists ? <script type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(productPageMarkup) }}></script> : null}
        </Head>
        <PageBuilderProvider data={{ staticData, marketData }}>
          <Box>
            <PageResolver pageData={pageData} />
            <Blocks {...pageData} />
          </Box>
        </PageBuilderProvider>
      </>
    );
  }

  return null;
}

const ConvertEpiImageToCloudinary = (imageSrc: string, imageWidth: number) => {
  const { masterImageSource } = getConfig();

  let cleanSrc = imageSrc.split("?")[0]; // remove all after query string, take the close to original to cloudinary
  if (masterImageSource) {
    // replace main source url to canonical image source to avoid duplicate images in cloudinary from different environments
    const urlParts = cleanSrc.split("/");
    cleanSrc = [masterImageSource, ...urlParts.slice(3)].join("/");
  }
  // use cloudinary resize commamnd c_scale,w_${src.width} to scale down image to with sent to the control
  return `https://res.cloudinary.com/dfwzl0ito/image/fetch/c_scale,w_${imageWidth
    },f_auto/${encodeURIComponent(cleanSrc)}`;
}

export default PageBuilderComponent;
