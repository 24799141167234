import { useEffect, useState } from "react";

import { throttle } from "../util";

export default function useWindowSize(): { width: number; height: number } {
  const [windowSize, setWindowSize] = useState(
    typeof window !== "undefined"
      ? { width: window.innerWidth, height: window.innerHeight }
      : { width: undefined, height: undefined }
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const throttleOnResize = throttle(handleResize, 100);
    window.addEventListener("resize", throttleOnResize);
    handleResize();
    return () => window.removeEventListener("resize", throttleOnResize);
  }, []);

  return windowSize;
}
