import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Link as Anchor, Box, Heading, Text } from "@chakra-ui/react";
import Image from "@components/Image";
import NextLink from "next/link";

import type { ExploreItemType } from "./ExploreBlock.model";

export function ExploreItem({
  Title,
  Description,
  Image: image,
  Link: link,
  width,
  Ratio,
}: ExploreItemType) {
  return (
    <Box position="relative" width={width}>
      <NextLink href={link?.Url || ""} passHref>
        <Anchor>
          <Box overflow="hidden">
            <Box
              sx={{
                transitionProperty: "transform",
                transitionDuration: "imageHover",
                transitionTimingFunction: "imageHover",
              }}
              _hover={{
                transform: "scale(1.1)",
                cursor: "pointer",
              }}
            >
              <Image
                alt={image?.AlternativeText}
                src={image?.Url}
                sizes="600px"
                aspectRatio={Ratio || "4:5"}
                objectFit="cover"
                quality={90}
              />
            </Box>
          </Box>
        </Anchor>
      </NextLink>
      <Heading as="h3" variant="heading4" marginY={4}>
        {Title}
      </Heading>
      <Text>{Description}</Text>
      <NextLink href={link?.Url || ""} passHref>
        <Anchor display="flex" alignItems="center" my={6}>
          <Text variant="cta" mr={2}>
            {link?.Title}
          </Text>
          <ArrowForwardIcon />
        </Anchor>
      </NextLink>
    </Box>
  );
}
