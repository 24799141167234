import {
  Button,
  ButtonProps,
  Flex,
  Input,
  useNumberInput,
} from "@chakra-ui/react";
import useBasket from "@hooks/useBasket";

interface QuantityButtonProps {
  productId: string;
  removeText?: string;
}

function QuantityButton({
  productId,
  removeText,
}: ButtonProps & QuantityButtonProps) {
  const { upsertBasketLine, getQuantity, loading } = useBasket();
  const currentQuantity = getQuantity(productId);

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      onChange(_, valueAsNumber) {
        if (valueAsNumber) {
          upsertBasketLine(productId, valueAsNumber);
        }
      },
      precision: 0,
      step: 1,
      defaultValue: currentQuantity,
      min: 1,
      max: 199,
    });

  const dec = getDecrementButtonProps();
  const inc = getIncrementButtonProps();
  const input = getInputProps();

  const handleRemove = () => {
    upsertBasketLine(productId, 0);
  };

  return (
    <Flex justifyContent="center" flexDirection="column" width="100px">
      <Flex justifyContent="space-between" alignItems="center">
        <Button
          {...dec}
          variant="link"
          _hover={{ textDecoration: "none" }}
          fontSize="xx-large"
        >
          -
        </Button>
        <Input
          {...input}
          width="35px"
          height="35px"
          borderRadius={0}
          borderWidth="1px"
          color="black"
          bgColor="brand.gray.300"
          borderColor="white"
          focusBorderColor="black"
          _hover={{ borderColor: "brand.gray.900" }}
          lineHeight="35px"
          paddingInline={0}
          textAlign="center"
        />
        <Button
          {...inc}
          variant="link"
          _hover={{ textDecoration: "none" }}
          fontSize="xx-large"
        >
          +
        </Button>
      </Flex>
      {removeText ? (
        <Button
          onClick={handleRemove}
          variant="link"
          isLoading={loading}
          height={5}
          mt={2}
        >
          {removeText}
        </Button>
      ) : (
        <></>
      )}
    </Flex>
  );
}

export default QuantityButton;
