import {
  Box,
  Container,
  GridItem,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import Image from "@components/Image";
import { ReactElement } from "react";

import type { ImageGalleryBlockType } from "./ImageGalleryBlock.model";

function getAspectRatio(width: number, height: number): "4:5" | "16:9" | "1:1" {
  if (width > height) return "16:9";
  if (height > width) return "4:5";
  return "1:1";
}

export function ImageGalleryBlock(data: ImageGalleryBlockType): ReactElement {
  const firstImageFormat = getAspectRatio(
    data?.FirstImage?.Width,
    data?.FirstImage?.Height
  );
  const secondImageFormat = getAspectRatio(
    data?.SecondImage?.Width,
    data?.SecondImage?.Height
  );

  const widthValue = {
    base: data.SecondImage
      ? firstImageFormat === "16:9" && secondImageFormat === "16:9"
        ? "100%"
        : "100%"
      : firstImageFormat === "16:9"
      ? "100%"
      : "100%",
    md: data.SecondImage
      ? firstImageFormat === "16:9" && secondImageFormat === "16:9"
        ? "100%"
        : "100%"
      : firstImageFormat === "16:9"
      ? "95%"
      : "47.5%",
  };

  return (
    <Container className={data.Kind} paddingY={10}>
      {data.Heading && (
        <Heading
          as="h2"
          size="lg"
          fontWeight="500"
          width="100%"
          paddingX={{ base: 4, lg: 10 }}
        >
          {data.Heading}
        </Heading>
      )}
      <SimpleGrid
        columns={{
          base: 1,
          md: data.FirstImage && data.SecondImage ? 2 : 1,
          lg: data.FirstImage && data.SecondImage ? 2 : 1,
        }}
        gap={4}
      >
        <GridItem
          order={1}
          paddingLeft={
            data.SecondImage ? { base: 0, lg: 10 } : { base: 0, lg: 0 }
          }
          paddingTop={{ base: 4 }}
        >
          <Box
            display="flex"
            justifyContent={{
              base: "center",
              md: data.SecondImage ? "right" : "center",
            }}
            alignItems="center"
            height="100%"
          >
            {data.FirstImage && (
              <Box width={widthValue}>
                <Image
                  src={data.FirstImage.Url}
                  alt={data.FirstImage.AlternativeText}
                  aspectRatio={firstImageFormat}
                  size="full"
                  sizes="(max-width: 1200px) 100vw,
                       1920px"
                  objectFit={
                    data.FirstImage && data.SecondImage ? "cover" : undefined
                  }
                  {...data.FirstImage}
                />
              </Box>
            )}
          </Box>
        </GridItem>
        {data.SecondImage && (
          <GridItem
            order={2}
            paddingRight={{ base: 0, lg: 10 }}
            paddingTop={{ base: 4 }}
          >
            <Box
              display="flex"
              justifyContent={{ base: "center", md: "left" }}
              alignItems="center"
              height="100%"
            >
              <Box width={widthValue}>
                <Image
                  src={data.SecondImage.Url}
                  alt={data.SecondImage.AlternativeText}
                  aspectRatio={secondImageFormat}
                  size="full"
                  sizes="(max-width: 1200px) 100vw,
                       1920px"
                  objectFit={
                    data.FirstImage && data.SecondImage ? "cover" : undefined
                  }
                  {...data.SecondImage}
                />
              </Box>
            </Box>
          </GridItem>
        )}
      </SimpleGrid>
    </Container>
  );
}

export default ImageGalleryBlock;
