import { Link as Anchor, Box, Heading, Text } from "@chakra-ui/react";
import Image from "@components/Image";
import NextLink from "next/link";

import type { ProductGalleryItemType } from "./ProductGalleryItem.model";

export function ProductGalleryItem({
  PageUrl,
  Heading: heading,
  Image: image,
  Teaser,
}: ProductGalleryItemType) {
  return (
    <Box position="relative">
      <NextLink href={PageUrl} passHref>
        <Anchor>
          <Box overflow="hidden">
            <Box
              sx={{
                transitionProperty: "transform",
                transitionDuration: "imageHover",
                transitionTimingFunction: "imageHover",
              }}
              _hover={{
                transform: "scale(1.1)",
                cursor: "pointer",
              }}
            >
              <Image
                alt={image?.AlternativeText}
                src={image?.Url}
                sizes="25vw"
                size="medium"
                aspectRatio="5:6"
                objectFit="contain"
              />
            </Box>
          </Box>
        </Anchor>
      </NextLink>
      <NextLink href={PageUrl} passHref>
        <Anchor>
          <Heading as="h2" size="md" marginY={4}>
            {heading}
          </Heading>
        </Anchor>
      </NextLink>
      <Text fontSize="sm">{Teaser}</Text>
    </Box>
  );
}
