import { Button, List, ListItem } from "@chakra-ui/react";

import { StoreLocatorOverviewType } from "./StoreLocator.model";
import StoreLocatorCard from "./StoreLocatorCard";

function StoreLocatorOverview({ stores, callback }: StoreLocatorOverviewType) {
  return (
    <List>
      {stores.map((store, i) => {
        return (
          <ListItem key={i}>
            <Button
              paddingY={3}
              paddingX={5}
              height="auto"
              width="100%"
              variant="clean"
              cursor="pointer"
              onClick={() => callback(store)}
              display="block"
              _focus={{ outline: "none" }}
              _hover={{ backgroundColor: "gray.50" }}
            >
              <StoreLocatorCard {...store} />
            </Button>
          </ListItem>
        );
      })}
    </List>
  );
}

export default StoreLocatorOverview;
