import { Link as Anchor, Box, Text } from "@chakra-ui/react";
import Image from "@components/Image";
import { ConditionalWrapper } from "libs/chs-core/util/ConditionalWrapper";
import NextLink from "next/link";

import type { ProductCarouselItemType } from "./ProductCarouselItem.model";

export function ProductCarouselItem({
  ProductName,
  ProductMaterials,
  Image: image,
  Link: link,
}: ProductCarouselItemType) {
  return (
    <Box position="relative" p={6} bgColor="studio.white">
      <ConditionalWrapper
        condition={!!link?.Url}
        wrapper={(children) => (
          <NextLink href={link?.Url} passHref>
            <Anchor>{children}</Anchor>
          </NextLink>
        )}
      >
        <Box overflow="hidden">
          <Box
            sx={{
              transitionProperty: "transform",
              transitionDuration: "imageHover",
              transitionTimingFunction: "imageHover",
            }}
            _hover={{
              transform: "scale(1.1)",
            }}
          >
            <Image
              alt={image?.AlternativeText}
              src={image?.Url}
              sizes="400px"
              aspectRatio="1:1"
              objectFit="contain"
            />
          </Box>
        </Box>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={!!link?.Url}
        wrapper={(children) => (
          <NextLink href={link?.Url} passHref>
            <Anchor>{children}</Anchor>
          </NextLink>
        )}
      >
        <Text variant="medium" noOfLines={1} height="2rem">
          {ProductName}
        </Text>
      </ConditionalWrapper>
      <Text color="studio.gray.700" noOfLines={2} height="3rem">
        {ProductMaterials}
      </Text>
    </Box>
  );
}
