import { Box, Container } from "@chakra-ui/react";
import { ReactElement } from "react";

import type { HTMLBlockType } from "./HTMLBlock.model";

function HTMLBlock(data: HTMLBlockType): ReactElement {
  return (
    <Container className={data.Kind} position="relative">
      <Box dangerouslySetInnerHTML={{ __html: data.HTML }} />
    </Container>
  );
}

export default HTMLBlock;
