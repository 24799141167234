import {
  Box,
  Container,
  Divider,
  Heading,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
// import useJobs from "@hooks/useJobs";
import { useLocale, useTranslations } from "next-intl";
import { useRouter } from "next/router";
import Script from "next/script";
import { ReactElement, useEffect } from "react";

import type { HrCloudBlockType } from "./HrCloudBlock.model";

function HrCloudBlock(data: HrCloudBlockType): ReactElement {
  // const dict = useTranslations("Shared.HrCloud");

  const locale = useLocale();
  const router = useRouter();
  let hrOnScript = `getScript(HR_WEB_ROOT+'js/easyXDM/easyXDM.min.js', function() {
        var hrsky = new HRSkyen({
            companyId: 367,
            persistent: {
              theme:"CarlHansenSn-v2",
              joblisturl: location.href,
              joburlfilter: "English"
            },
            locale: "en_US",
            container: "hr-on",
            queryKey: "hr"
        });
    });`;
  if (locale === "da-dk") {
    hrOnScript = `getScript(HR_WEB_ROOT+'js/easyXDM/easyXDM.min.js', function() {
            var hrsky = new HRSkyen({
                companyId: 367,
                persistent: {
                  theme:"CarlHansenSn-v2",
                  joblisturl: location.href,
                  joburlfilter: "Dansk",
                },
                locale: "da_DK",
                container: "hr-on",
                queryKey: "hr"
            });
        });`;
  }

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://recruit.hr-on.com/frame-api/hr.js";
    document.body.appendChild(script1);
    script1.onload = () => {
      eval(hrOnScript);
    };

    return () => {
      document.body.removeChild(script1);
    };
  }, [router]); // router prop or w/e

  return (
    <Container pt={0} className={data.Kind}>
      <div id="hr-on"></div>
    </Container>
  );

  // const { jobsData, loading, error, fetchJobs } = useJobs(locale);

  // useEffect(() => {
  //     fetchJobs();
  // }, []);

  // return (
  //     <Container
  //         pt={0}
  //         className={data.Kind}
  //     >
  //         {!jobsData ? (
  //             <Heading pb={4} as="h2" size="md">
  //                 {dict("TextNoVacanciesAvailable")}
  //             </Heading>) : (
  //             <>
  //                 <Heading pb={4} as="h2" size="md">{data.Heading}</Heading>

  //                 <VStack py={4} flexDirection="row" display={{ base: "none", sm: "flex" }}>
  //                     <Box w="70%">
  //                         <Text fontWeight="700"> {dict("TextRole")}</Text>
  //                     </Box>
  //                     <Box w="15%">
  //                         <Text fontWeight="700"> {dict("TextLocation")}</Text>
  //                     </Box>
  //                     <Box w="15%">
  //                         <Text fontWeight="700" textAlign="right"> {dict("TextApplyBefore")}</Text>
  //                     </Box>
  //                 </VStack>
  //                 <Divider borderColor="brand.gray.300" />

  //                 {jobsData.map((job, i) => (
  //                     <>
  //                         <VStack py={4} flexDirection={{ base: "column", sm: "row" }} key={i}>
  //                             <Box py={2} w={{ base: "100%", sm: "70%" }}>
  //                                 <Link href={job?.link} target="_blank">
  //                                     <Text>{job?.title}</Text>
  //                                 </Link>
  //                             </Box>
  //                             <Box w={{ base: "100%", sm: "30%" }} display="flex">
  //                                 <Box py={2} w={{ base: "50%", sm: "100%" }} >
  //                                     <Text>{job?.locationwork}</Text>
  //                                 </Box>
  //                                 <Box py={2} w={{ base: "50%", sm: "100%" }}>
  //                                     <Text textAlign="right">{job?.applybefore}</Text>
  //                                 </Box>
  //                             </Box>
  //                         </VStack>
  //                         <Divider borderColor="brand.gray.300" />
  //                     </>
  //                 ))}
  //             </>
  //         )}
  //     </Container>
  // );
}

export default HrCloudBlock;
