import { Box, Container, Heading } from "@chakra-ui/react";
import MotionBox from "@components/MotionBox.component";
import RichTextComponent from "@components/RichText.component";
import { useAnimation } from "framer-motion";
import Link from "next/link";
import { ReactElement, useEffect } from "react";

import type { HeroNoMediaBlockType } from "./HeroNoMediaBlock.model";

function HeroNoMediaBlockComponent(data: HeroNoMediaBlockType): ReactElement {
  const animationControls = useAnimation();

  useEffect(() => {
    animationControls.start({
      opacity: [0, 1],
      translateY: ["50%", "0%"],
    });

    return () => {
      animationControls.stop();
    };
  }, [data]); // eslint-disable-line

  return (
    <Container
      pt="15vh"
      pb="80px"
      maxWidth="calc((100vw - 24px * 2 + 6px * 2)/ 12 * 10)"
      className={data.Kind}
    >
      <MotionBox
        initial={{ translateY: "50%", opacity: 0 }}
        animate={animationControls}
        ml="calc((100vw - 48px * 2 + 12px * 2)/ 12 * 2);"
      >
        {data.Heading && (
          <Heading variant="heading1" as="h1">
            {data.Heading}
          </Heading>
        )}
        {data.SubHeading && (
          <Heading as="h2" size="md">
            {data.SubHeading}
          </Heading>
        )}
        {data.TeaserText && (
          <Box fontSize="2xs" marginY={5}>
            <RichTextComponent text={data.TeaserText} />
          </Box>
        )}
        {data.Link && (
          <Link href={data.Link.Url}>{data.Link.Title || data.Link.Url}</Link>
        )}
      </MotionBox>
    </Container>
  );
}

export default HeroNoMediaBlockComponent;
