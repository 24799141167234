import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";
import RichTextComponent from "@components/RichText.component";
import { ReactElement } from "react";

import type { CopyBlockType } from "./CopyBlock.model";

export function CopyBlock({
  Heading: heading,
  SubHeading,
  Column1,
  Column2,
  Kind,
}: CopyBlockType): ReactElement {
  return (
    <Container py={{ base: 6, md: 10 }} className={Kind} maxW="container.lg">
      <Heading variant="heading2" pb={10}>
        {heading}
      </Heading>
      <Flex
        gap={{ base: 6, md: 10, lg: 20 }}
        direction={{ base: "column", md: "row" }}
      >
        {SubHeading && (
          <Box width="100%">
            <Text variant="teaser">{SubHeading}</Text>
          </Box>
        )}
        {Column1 && (
          <Box width="100%">
            <RichTextComponent text={Column1} />
          </Box>
        )}
        {Column2 && (
          <Box width="100%">
            <RichTextComponent text={Column2} />
          </Box>
        )}
      </Flex>
    </Container>
  );
}

export default CopyBlock;
